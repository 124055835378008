<template>
  <el-main>
    <div class="title_tab">
      {{ name }}
      <div style="float: right; line-height: 35px">
        <!-- <div
          style="
            color: #efb43c;
            text-align: center;
            font-weight: 500;
            display: initial;
            margin-right: 40px;
          "
        >
          <span style="font-size: 1.2em">GG共享: </span>
          <span style="font-size: 1em">{{ gshareusdmoney }}</span>
        </div> -->
        <div style="
            color: #efb43c;
            text-align: center;
            font-weight: 500;
            display: initial;
            margin-right: 40px;
          ">
          <span style="font-size: 1.2em">PP共享: </span>
          <span style="font-size: 1em">{{ pshareusdmoney }}</span>
        </div>
        <div style="
            color: #efb43c;
            text-align: center;
            font-weight: 500;
            display: initial;
            margin-right: 40px;
          ">
          <span style="font-size: 1.2em">CNY总额: </span>
          <span style="font-size: 1em">{{ cny }}</span>
        </div>
        <div style="
            color: #efb43c;
            text-align: center;
            font-weight: 500;
            display: initial;
          ">
          <span style="font-size: 1.2em">USD总额: </span>
          <span style="font-size: 1em">{{ usd }}</span>
        </div>
      </div>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <!-- 搜索框 -->
          <div class="user_box">
            <el-form :model="form_balance" :rules="form_balanceRules" ref="form_balanceFormRef"
              :label-position="labelPosition">
              <!-- 输入卡号 -->
              <el-input v-model="form_balance.cardNo" prefix-icon="el-icon-tickets" placeholder="请输入卡号">
              </el-input>
              <!-- 日期选择 -->
              <el-date-picker v-model="dataTimes" type="daterange" align="right" unlink-panels range-separator="-"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions2"
                format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
              </el-date-picker>
              <!-- 事件类型 -->
              <el-select v-model="selectVlues" placeholder="请选择事件类型" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- 支付状态 -->
              <el-select v-model="selelctRechargeStatusVal" placeholder="请选择支付状态" clearable>
                <el-option v-for="item in selelctRechargeStatus" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-button type="primary" icon="el-icon-search" @click="searchUser()">搜 索</el-button>
              <el-button type="primary" icon="el-icon-refresh" @click="searchUser('1')">刷 新</el-button>
            </el-form>
          </div>
          <!-- 表格 + 分页 -->
          <div class="user_box">
            <el-table :data="tableData" style="width: 100%" border height="640" max-height="800" size="small"
              highlight-current-row>
              <el-table-column label="编号" prop="mycard_id" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="所属用户" prop="users"> </el-table-column>
              <el-table-column label="操作人员" prop="actionusers">
              </el-table-column>
              <el-table-column label="操作时间" prop="addtime">
              </el-table-column>
              <el-table-column label="操作事件">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.source === 1"> 充值 </el-tag>
                  <el-tag v-else-if="scope.row.source === 2"> 卡转出 </el-tag>
                  <el-tag v-else-if="scope.row.source === 3"> 开卡费 </el-tag>
                  <el-tag v-else-if="scope.row.source === 4"> 销卡转入 </el-tag>
                  <!-- <el-tag v-else-if="scope.row.source === 5"> 撤销转入 </el-tag> -->
                  <el-tag v-else-if="scope.row.source === 6"> 网关失败 </el-tag>
                  <el-tag v-else-if="scope.row.source === 7">
                    跨境手续费
                  </el-tag>
                  <!-- <el-tag v-else-if="scope.row.source === 8">
                    补缴手续费
                  </el-tag>
                  <el-tag v-else-if="scope.row.source === 9">
                    退款手续费
                  </el-tag> -->
                  <el-tag v-else-if="scope.row.source === 10">
                    交易手续费
                  </el-tag>
                  <el-tag v-else-if="scope.row.source === 11">
                    广告费支付
                  </el-tag>
                  <el-tag v-else-if="scope.row.source === 12">
                    广告费退回
                  </el-tag>
                </template>
              </el-table-column>
              <!-- 支付状态 -->
              <el-table-column prop="status" label="支付状态" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 1" type="success">
                    成功
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 2" type="danger">
                    失败
                  </el-tag>
                </template>
              </el-table-column>

              <el-table-column label="金额" prop="changem"> </el-table-column>
              <el-table-column label="钱包余额" prop="aftermon">
              </el-table-column>
              <el-table-column label="USD总余额" prop="usdcount">
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[50, 100, 150, 200]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      gshareusdmoney: "",
      pshareusdmoney: "",
      cny: "",
      usd: "",
      name: "美金流水",
      total: 0,
      labelPosition: "right",
      selectVlues: "",
      selelctRechargeStatusVal: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 50, // 每页数据显示 条数
      dataTimes: [],
      form_balance: {
        cardNo: "",
      },
      form_balanceRules: {},
      // 支付状态
      selelctRechargeStatus: [
        {
          value: "1",
          label: "成功",
        },
        {
          value: "2",
          label: "失败",
        },
      ],
      // 选择事件类型
      options: [
        {
          value: "1",
          label: "充值",
        },
        {
          value: "2",
          label: "卡转出",
        },
        {
          value: "3",
          label: "开卡费",
        },
        {
          value: "4",
          label: "销卡转入",
        },
        // {
        //   value: "5",
        //   label: "撤销转入",
        // },
        {
          value: "6",
          label: "网关失败",
        },
        {
          value: "7",
          label: "跨境手续费",
        },
        // {
        //   value: "8",
        //   label: "补缴手续费",
        // },
        // {
        //   value: "9",
        //   label: "退款手续费",
        // },
        {
          value: "10",
          label: "交易手续费",
        },
        {
          value: "11",
          label: "广告费支付",
        },
        {
          value: "12",
          label: "广告费退回",
        },
      ],
      pickerOptions2: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    getCommonNum() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getCommonNum", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.cny = res.data.data.cnycount;
          this.usd = res.data.data.usdcount;
          this.pshareusdmoney = res.data.data.pshareusdmoney;
          this.gshareusdmoney = res.data.data.gshareusdmoney;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 搜索
    searchUser(rf) {
      if (rf == "1") {
        this.currentPage = 1;
        this.pageSize = 50;
        this.form_balance.cardNo = "";
        this.selectVlues = "";
        this.selelctRechargeStatusVal = "";
        this.dataTimes = [];
      }
      if (this.dataTimes[0] == undefined && this.dataTimes[1] == undefined) {
        this.dataTimes[0] = "";
        this.dataTimes[1] = "";
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        card_num: this.form_balance.cardNo,
        event_type: this.selectVlues,
        pay_type: this.selelctRechargeStatusVal,
        start: this.dataTimes[0],
        ends: this.dataTimes[1],
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getManUsdR", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].addtime = this.getTime(
              res.data.data.data[i].addtime
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.searchUser();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchUser();
    },
  },
  created() {
    this.searchUser();
    this.getCommonNum();
  },
  mounted() { },
};
</script>

<style scoped>
.el-select,
.el-input--prefix {
  margin-right: 1%;
}

.el-input--prefix {
  width: 15%;
}

.el-range-editor {
  margin-right: 3%;
}

.el-button {
  margin-right: 2%;
}
</style>
