<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('balanceWithdrawals.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('balanceWithdrawals.title') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="container_tx">
          <!-- 头部分 -->
          <div class="container_tx-header">
            <div class="container_tx-header-left">
              <!-- 提现元素 -->
              <div class="container_tx-header-left-card">
                <div class="container_tx-header-left-card-c1">{{ $t('balanceWithdrawals.walletBalance') }}</div>
                <div class="container_tx-header-left-card-c2">
                  ${{ usdmoney }}
                </div>
                <div class="container_tx-header-left-card-c3" @click="getWithdrainfo()">
                  {{ $t('balanceWithdrawals.payouts') }}
                </div>
              </div>
            </div>
            <div class="container_tx-header-right">
              <div class="container_tx-header-right-card">
                <div class="container_tx-header-right-card-left">
                  <div class="container_tx-header-right-card-left-icobox">
                    <img src="@/images/svg/tx/cny.svg" alt="" srcset="" />
                  </div>
                </div>
                <div class="container_tx-header-right-card-right">
                  <div class="container_tx-header-right-card-right-c1">
                    CNY{{ $t('balanceWithdrawals.withdrawalsAreFrozen') }}
                  </div>
                  <div class="container_tx-header-right-card-right-c2">
                    {{ cny_disabled_mon }}
                  </div>
                </div>
              </div>
              <div class="container_tx-header-right-card">
                <div class="container_tx-header-right-card-left">
                  <div class="container_tx-header-right-card-left-icobox">
                    <img src="@/images/svg/tx/usd.svg" alt="" srcset="" />
                  </div>
                </div>
                <div class="container_tx-header-right-card-right">
                  <div class="container_tx-header-right-card-right-c1">
                    USD{{ $t('balanceWithdrawals.withdrawalsAreFrozen') }}
                  </div>
                  <div class="container_tx-header-right-card-right-c2">
                    {{ usd_disabled_mon }}
                  </div>
                </div>
              </div>
              <div class="container_tx-header-right-card">
                <div class="container_tx-header-right-card-left">
                  <div class="container_tx-header-right-card-left-icobox">
                    <img src="@/images/svg/tx/hl.svg" alt="" srcset="" />
                  </div>
                </div>
                <div class="container_tx-header-right-card-right">
                  <div class="container_tx-header-right-card-right-c1">
                    USD{{ $t('balanceWithdrawals.withdrawalExchangeRate') }}
                  </div>
                  <div class="container_tx-header-right-card-right-c2">
                    {{ usdlv }}
                  </div>
                </div>
              </div>
              <div class="container_tx-header-right-card">
                <div class="container_tx-header-right-card-left">
                  <div class="container_tx-header-right-card-left-icobox">
                    <img src="@/images/svg/tx/jb.svg" alt="" srcset="" />
                  </div>
                </div>
                <div class="container_tx-header-right-card-right">
                  <div class="container_tx-header-right-card-right-c1">
                    CNY{{ $t('balanceWithdrawals.withdrawalExchangeRate') }}
                  </div>
                  <div class="container_tx-header-right-card-right-c2">
                    {{ cnylv }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 表格部分 -->
          <div class="container_tx-table">
            <el-table :data="disData" style="width: 100%">
              <el-table-column prop="order_num" align="center" :show-overflow-tooltip="true"
                :label="$t('balanceWithdrawals.orderNumber')">
              </el-table-column>
              <el-table-column prop="users" align="center" :label="$t('balanceWithdrawals.withdrawalUsername')"
                width="120">
              </el-table-column>
              <el-table-column prop="money" align="center" :label="$t('balanceWithdrawals.theAmountToBeWithdrawn')"
                width="100">
              </el-table-column>
              <el-table-column prop="types" align="center" :label="$t('balanceWithdrawals.withdrawalCurrency')"
                width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.types === 1"> CNY </el-tag>
                  <el-tag v-else-if="scope.row.types === 2" type="success">
                    USD
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="status" :label="$t('balanceWithdrawals.payoutStatus')" width="180">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.status === 1">
                    {{ $t('balanceWithdrawals.underReview') }}
                  </el-tag>
                  <el-tag type="success" v-else-if="scope.row.status === 2">
                    {{ $t('balanceWithdrawals.approved') }}
                  </el-tag>
                  <el-tag type="danger" v-else-if="scope.row.status === 3">
                    {{ $t('balanceWithdrawals.reviewRejection') }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="zfbuser" align="center" :label="$t('balanceWithdrawals.alipayAccount')">
              </el-table-column>
              <el-table-column prop="names" align="center" :label="$t('balanceWithdrawals.alipayName')" width="140">
              </el-table-column>
              <el-table-column prop="remark" :label="$t('balanceWithdrawals.withdrawalRequestRemarks')" align="center"
                :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="refusecon" :label="$t('balanceWithdrawals.approvalNotes')" align="center"
                :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.refusecon === null">
                    {{ $t('balanceWithdrawals.underReview') }}
                  </el-tag>
                  <span v-else>
                    {{ scope.row.refusecon }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="replaytime" align="center" :label="$t('balanceWithdrawals.reviewTime')"
                width="170">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.replaytime === null">
                    {{ $t('balanceWithdrawals.underReview') }}
                  </el-tag>
                  <span v-else>
                    {{ scope.row.replaytime }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="createtime" align="center" :label="$t('balanceWithdrawals.withdrawalTime')"
                width="170">
              </el-table-column>
              <el-table-column prop="curlv" align="center" :label="$t('balanceWithdrawals.withdrawalExchangeRate')"
                width="80">
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 500, 1000]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>
        <!-- <div class="grid-content bg-purple">
          <div class="user_box"></div>
        </div> -->
        <div class="grid-content bg-purple" v-if="false">
          <div class="user_box">
            <div style="margin-bottom: 20px">
              <!-- 提现状态 -->
              <el-select v-model="status" :placeholder="$t('balanceWithdrawals.payoutStatus')" clearable
                style="margin-right: 20px">
                <el-option v-for="item in statusoptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- 交易币种 -->
              <el-select v-model="types" :placeholder="$t('balanceWithdrawals.theCurrencyOfTheTransaction')" clearable
                style="margin-right: 20px">
                <el-option v-for="item in typesoptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- 日期选择 -->
              <!-- <el-date-picker v-model="dataTimes" type="daterange" align="right" unlink-panels range-separator="-"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions2"
                format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
              </el-date-picker> -->
              <el-button type="primary" style="margin-left: 20px" icon="el-icon-search" @click="getUserWithdinfo()">{{
                $t('balanceWithdrawals.search') }}
              </el-button>
              <el-button type="primary" icon="el-icon-refresh" @click="getUserWithdinfo('1')">{{
                $t('balanceWithdrawals.flushed') }}</el-button>
              <el-button type="primary" v-no-more-click style="margin-right: 10px" @click="getWithdrainfo()">{{
                $t('balanceWithdrawals.withdrawalRequests') }}
              </el-button>
              <el-tag effect="dark" type="danger" style="margin-right: 10px; height: 39px; line-height: 39px">
                CNY{{ $t('balanceWithdrawals.withdrawalsAreFrozen') }}：{{ cny_disabled_mon }}</el-tag>
              <el-tag effect="dark" type="danger" style="margin-right: 10px; height: 39px; line-height: 39px">
                USD{{ $t('balanceWithdrawals.withdrawalsAreFrozen') }}：{{ usd_disabled_mon }}</el-tag>
              <el-tag effect="dark" style="margin-right: 10px; height: 39px; line-height: 39px">USD{{
                $t('balanceWithdrawals.withdrawalExchangeRate') }}：{{
                  usdlv }}
              </el-tag>
              <el-tag effect="dark" style="height: 39px; line-height: 39px">CNY{{
                $t('balanceWithdrawals.withdrawalExchangeRate') }}：{{ cnylv
                }}</el-tag>
            </div>
            <el-table :data="disData" border style="width: 100%">
              <el-table-column prop="order_num" :label="$t('balanceWithdrawals.orderNumber')">
              </el-table-column>
              <el-table-column prop="users" :label="$t('balanceWithdrawals.withdrawalUsername')" width="120">
              </el-table-column>
              <el-table-column prop="money" :label="$t('balanceWithdrawals.theAmountToBeWithdrawn')" width="100">
              </el-table-column>
              <el-table-column prop="types" :label="$t('balanceWithdrawals.withdrawalCurrency')" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.types === 1"> CNY </el-tag>
                  <el-tag v-else-if="scope.row.types === 2" type="success">
                    USD
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="status" :label="$t('balanceWithdrawals.payoutStatus')" width="100">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.status === 1">
                    {{ $t('balanceWithdrawals.underReview') }}
                  </el-tag>
                  <el-tag type="success" v-else-if="scope.row.status === 2">
                    {{ $t('balanceWithdrawals.approved') }}
                  </el-tag>
                  <el-tag type="danger" v-else-if="scope.row.status === 3">
                    {{ $t('balanceWithdrawals.reviewRejection') }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="zfbuser" :label="$t('balanceWithdrawals.alipayAccount')">
              </el-table-column>
              <el-table-column prop="names" :label="$t('balanceWithdrawals.alipayName')" width="140">
              </el-table-column>
              <el-table-column prop="remark" :label="$t('balanceWithdrawals.withdrawalRequestRemarks')"
                :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="refusecon" :label="$t('balanceWithdrawals.approvalNotes')"
                :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.refusecon === null">
                    {{ $t('balanceWithdrawals.underReview') }}
                  </el-tag>
                  <span v-else>
                    {{ scope.row.refusecon }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="replaytime" :label="$t('balanceWithdrawals.reviewTime')" width="170">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.replaytime === null">
                    {{ $t('balanceWithdrawals.underReview') }}
                  </el-tag>
                  <span v-else>
                    {{ scope.row.replaytime }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="createtime" :label="$t('balanceWithdrawals.withdrawalTime')" width="170">
              </el-table-column>
              <el-table-column prop="curlv" :label="$t('balanceWithdrawals.withdrawalExchangeRate')" width="80">
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 500, 1000]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>
        <!-- 提现申请弹窗 -->
        <el-dialog :title="$t('balanceWithdrawals.withdrawalRequests')" :visible.sync="dialogFormVisible" width="60%"
          custom-class="ls">
          <!-- 提现表单 -->
          <div class="form_tx">
            <!-- <div class="form_tx_type">
              <div class="form_tx_type_header">
                <div
                  :class="[
                    radio == '1'
                      ? 'form_tx_type_header_type active'
                      : 'form_tx_type_header_type ',
                  ]"
                  @click="radioChange('1')"
                >
                  人民币
                </div>
                <div
                  :class="[
                    radio == '2'
                      ? 'form_tx_type_header_type active'
                      : 'form_tx_type_header_type ',
                  ]"
                  @click="radioChange('2')"
                >
                  美元
                </div>
                <div ref="check" class="check"></div>
              </div>
              <div class="form_tx_type_img">
                <div class="imgbox">
                  <img ref="img1" class="img1" src="@/images/rmb.jpeg" alt="" />
                  <img ref="img2" class="img2" src="@/images/my.jpeg" alt="" />
                </div>
              </div>
            </div> -->
            <!-- 填写内容 -->
            <div class="form_tx_form">
              <div class="form_tx_form_header">
                <div class="form_tx_form_header_left">
                  <div class="form_tx_form_header_left_ico">
                    <img src="@/images/svg/tx/user.png" alt="" srcset="" />
                  </div>
                  <div class="form_tx_form_header_left_text">
                    {{ userNames }}
                  </div>
                </div>
                <div class="form_tx_form_header_right">
                  <!-- <div
                    :class="[
                      radio == '1'
                        ? 'form_tx_type_header_type active'
                        : 'form_tx_type_header_type ',
                    ]"
                    @click="radioChange('1')"
                  >
                    人民币
                  </div> -->
                  <div :class="[
                    radio == '2'
                      ? 'form_tx_type_header_type active'
                      : 'form_tx_type_header_type ',
                  ]" @click="radioChange('2')">
                    {{ $t('balanceWithdrawals.dollar') }}
                  </div>
                  <div ref="check" class="check"></div>
                </div>
              </div>
              <div class="form_tx_form_content">
                <div class="form_tx_form_content_left">
                  <div class="form_tx_form_content_left_l1">
                    <div>{{ $t('balanceWithdrawals.theAmountToBeWithdrawn') }}:</div>
                    <el-input type="text" style="width: 70%;" v-model="money" @input="getEndMony()" />
                  </div>
                  <div class="form_tx_form_content_left_l1">
                    <div>{{ $t('balanceWithdrawals.alipayAccount') }}:</div>
                    <el-input type="text" style="width: 70%;" v-model="zfbuser" />
                  </div>
                  <div class="form_tx_form_content_left_l1">
                    <div>{{ $t('balanceWithdrawals.alipayName') }}:</div>
                    <el-input type="text" style="width: 70%;" v-model="names" disabled />
                  </div>
                </div>
                <div class="form_tx_form_content_right">
                  <div class="form_tx_form_content_right_l1">
                    <div>CNY{{ $t('balanceWithdrawals.balance') }}:</div>
                    <input type="text" v-model="cnymoney" disabled />
                  </div>
                  <div class="form_tx_form_content_right_l1">
                    <div>USD{{ $t('balanceWithdrawals.balance') }}:</div>
                    <input type="text" v-model="usdmoney" disabled />
                  </div>
                  <div class="form_tx_form_content_right_l1">
                    <div>{{ $t('balanceWithdrawals.theAmountReceived') }}:</div>
                    <input type="text" v-model="endmoney" disabled />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="position: absolute; right: 45px; bottom: 20px">
            <button type="primary" class="comfirm_btn" v-no-more-click @click="setWithdraw()">
              {{ $t('balanceWithdrawals.submitAnApplication') }}
            </button>
          </div>
          <br />
          <br />
        </el-dialog>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      name: "余额提现",
      disData: [],
      dialogFormVisible: false,
      currentPage: 1,
      radio: "2",
      money: "0",
      endmoney: "0.00",
      remark: "",
      userNames: this.$cookie.get("user"),
      pageSize: 100,
      total: 0,
      usdmoney: "0.00",
      cnymoney: "0.00",
      zfbuser: "",
      names: "",
      dataTimes: [],
      cnylv: "0.00",
      usdlv: "0.0000",
      status: "",
      usd_disabled_mon: "0.00",
      cny_disabled_mon: "0.00",
      types: "",
      statusoptions: [
        {
          value: "1",
          label: this.$t('balanceWithdrawals.underReview'),
        },
        {
          value: "2",
          label: this.$t('balanceWithdrawals.approved'),
        },
        {
          value: "3",
          label: this.$t('balanceWithdrawals.reviewRejection'),
        },
      ],
      typesoptions: [
        {
          value: "1",
          label: "CNY",
        },
        {
          value: "2",
          label: "USD",
        },
      ],
      pickerOptions2: {
        shortcuts: [
          {
            text: this.$t('balanceWithdrawals.theLastWeek'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t('balanceWithdrawals.theLastMonth'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t('balanceWithdrawals.lastThreeMonths'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getUserWithdinfo();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserWithdinfo();
    },
    // 获取个人中心数据
    getuserinfos() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getuserinfos", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.usd_disabled_mon = res.data.data.usd_disabled_mon;
          this.cny_disabled_mon = res.data.data.cny_disabled_mon;
          this.names = res.data.data.realname;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    radioChange(value) {
      this.radio = value;
      // if (value == "1") {
      //   this.$refs.check.style.right = "100px";
      //   // this.$refs.img1.style.transform = "translateX(0%)";
      //   // this.$refs.img2.style.transform = "translateX(100%)";
      // } else {
      //   this.$refs.check.style.right = "0px";
      //   // this.$refs.img1.style.transform = "translateX(-100%)";
      //   // this.$refs.img2.style.transform = "translateX(0%)";
      // }
      this.getEndMony();
    },
    // 初始化
    initUserInfo() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getWithdrainfo", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          console.log('res: ', res);
          this.usdmoney = res.data.data.usdmoney;
          this.cnymoney = res.data.data.cnymoney;
          this.cnylv = res.data.data.cnylv;
          this.usdlv = res.data.data.usdlv;
          this.zfbuser = res.data.data.zfbuser;
          this.getEndMony();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 提现获取信息回调
    getWithdrainfo() {
      this.dialogFormVisible = true;
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getWithdrainfo", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.usdmoney = res.data.data.usdmoney;
          this.cnymoney = res.data.data.cnymoney;
          this.cnylv = res.data.data.cnylv;
          this.usdlv = res.data.data.usdlv;
          this.zfbuser = res.data.data.zfbuser;
          this.getEndMony();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 获取用户提现记录
    getUserWithdinfo(rf) {
      if (rf == "1") {
        this.currentPage = 1;
        this.pageSize = 100;
        this.status = "";
        this.types = "";
        this.dataTimes = [];
      }

      if (this.dataTimes[0] == undefined && this.dataTimes[1] == undefined) {
        this.dataTimes[0] = "";
        this.dataTimes[1] = "";
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        status: this.status,
        types: this.types,
        start: this.dataTimes[0],
        ends: this.dataTimes[1],
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUserWithdinfo", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.cnylv = res.data.data.lvdata.cnylv;
          this.usdlv = res.data.data.lvdata.usdlv;
          this.disData = res.data.data.data;
          this.total = res.data.data.total;
          for (let i = 0; i < this.disData.length; i++) {
            this.disData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
            if (this.disData[i].replaytime != null) {
              this.disData[i].replaytime = this.getTime(
                res.data.data.data[i].replaytime
              );
            }
          }
          this.getuserinfos();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 提现申请
    setWithdraw() {
      if (this.radio == "1") {
        if (this.money < 50 || this.money > 100000) {
          this.$message({
            message: "CNY提现金额不能低于50并且不能高于100000!",
            type: "error",
          });
          return;
        }
      } else if (this.radio == "2") {
        if (this.money < 10 || this.money > 100000) {
          this.$message({
            message: "USD提现金额不能低于10并且不能高于100000!",
            type: "error",
          });
          return;
        }
      }
      let params = {
        types: this.radio,
        money: this.money,
        endmoney: this.endmoney,
        zfbuser: this.zfbuser,
        name: this.names,
        remark: this.remark,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setWithdraw", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message({
              message: "申请体现成功，我们将在三天内处理您的请求！",
              type: "success",
            }),
              this.getUserWithdinfo(),
              this.getuserinfos(),
              (this.dialogFormVisible = false);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getEndMony() {
      console.log('this.cnylv: ', this.cnylv);
      console.log('this.usdlv: ', this.usdlv);
      console.log('this.money: ', this.money);

      if (this.radio == "1") {
        this.endmoney = this.money * this.cnylv;
        this.endmoney = Number(this.endmoney).toFixed(4);
        this.endmoney = this.endmoney.substring(0, this.endmoney.length - 2);
      } else {
        this.endmoney = this.money * this.usdlv;
        this.endmoney = Number(this.endmoney).toFixed(4);
        this.endmoney = this.endmoney.substring(0, this.endmoney.length - 2);
      }
      console.log('this.endmoney: ', this.endmoney);

    },
  },
  created() {
    this.getUserWithdinfo();
    this.initUserInfo();
  },
  mounted() { },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}

.container_tx {
  width: 100%;
  height: auto;

  // background-color: #fff;
  &-header {
    padding-top: 20px;
    display: flex;
    width: 100%;
    height: 300px;

    &-left {
      width: 30%;
      height: 100%;
      perspective: 700px;

      &-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        transition: all 200ms ease-out;
        background-image: linear-gradient(310deg, #2152ff, #21d4fd);

        &-c1 {
          color: #fff;
          font-size: 36px;
          font-weight: 400;
          letter-spacing: 0.9px;
        }

        &-c2 {
          margin-top: 25px;
          color: #fff;
          font-size: 30px;
          font-weight: 600;
          letter-spacing: 0.5px;
        }

        &-c3 {
          cursor: pointer;
          margin-top: 25px;
          width: 140px;
          height: 40px;
          letter-spacing: 0.5px;
          border-radius: 8px;
          text-align: center;
          line-height: 40px;
          color: #fff;
          font-size: 24px;
          border: 1px #fff solid;
          border-color: hsla(0, 0%, 100%, 0.4);
          background: hsla(0, 0%, 100%, 0.1);
        }

        &-c3:hover {
          background: transparent;
        }
      }

      &-card:hover {
        transform: scale(1.01) rotateX(3deg) rotateY(-3deg);
      }
    }

    &-right {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 70%;
      height: 100%;

      &-card {
        cursor: pointer;
        display: flex;
        width: 35%;
        height: 90px;
        border-radius: 16px;
        background-color: var(--mainCard);
        box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);

        &-left {
          padding: 14px;
          width: 30%;
          height: 100%;

          &-icobox {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            width: 100%;
            height: 100%;
            background-color: #111111;
          }
        }

        &-right {
          padding: 14px;
          width: 70%;
          height: 100%;

          &-c1 {
            color: #67748e;
            font-size: 20px;
            font-weight: 400;
          }

          &-c2 {
            color: var(--pageText);
            font-size: 22px;
          }
        }
      }

      &-card:hover img {
        animation: ico 500ms ease-out;
      }

      @keyframes ico {
        0% {
          transform: translateY(0px);
        }

        25% {
          transform: translateY(-8px);
        }

        50% {
          transform: translateY(-0px);
        }

        75% {
          transform: translateY(-3px);
        }

        100% {
          transform: translateY(0px);
        }
      }
    }
  }

  &-table {
    padding: 25px;
    margin-top: 24px;
    width: 100%;
    height: 420px;
    border-radius: 16px;
    background-color: var(--mainCard);
  }
}

.form_tx {
  width: 100%;
  height: auto;
  // border: 1px solid red;
  padding: 8px;
  display: flex;

  .form_tx_type {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 400px;
    border-radius: 12px;
    box-shadow: 0 0 68px 2px rgba(0, 0, 0, 0.1);

    &_header {
      position: relative;
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      color: #344767;
      font-weight: 700;
      padding: 12px;
      letter-spacing: 1.2px;
      filter: blur(50%);

      .check {
        cursor: pointer;
        position: absolute;
        top: 12px;
        z-index: 1;
        right: 112px;
        width: 100px;
        height: 40px;
        border-radius: 8px;
        transition: all 300ms ease-out;
        background-image: linear-gradient(310deg,
            rgba(121, 40, 202, 0.85),
            rgba(255, 0, 128, 0.85));
        box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, 0.9),
          0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
      }

      .active {
        color: #fff;
      }

      &_type {
        cursor: pointer;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 40px;
        z-index: 2;
      }
    }

    &_img {
      flex: 1;
      padding: 25px;
      width: 100%;

      .imgbox {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 12px;
        box-shadow: 1px 1px 25px 4px rgba(0, 0, 0, 0.2);

        .img1 {
          position: absolute;
          transition: all 300ms ease-out;
          transform: translateX(0%);
          width: 100%;
          height: 100%;
        }

        .img2 {
          position: absolute;
          transition: all 300ms ease-out;
          transform: translateX(100%);
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .form_tx_form {
    width: 100%;
    height: 400px;
    padding: 0 20px;

    &_header {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      height: 80px;
      border-radius: 12px;
      box-shadow: 0 0 68px 2px rgba(0, 0, 0, 0.1);

      &_left {
        display: flex;
        align-items: center;
        width: 50%;

        &_ico {
          width: 60px;
          height: 60px;
          // background-color: #111111;
          border-radius: 12px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &_text {
          margin-left: 25px;
          color: rgb(203, 12, 159);
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 1.7px;
        }
      }

      &_right {
        position: relative;
        display: flex;
        justify-content: flex-end;
        width: 50%;
        font-size: 16px;
        color: #344767;
        font-weight: 700;

        .check {
          cursor: pointer;
          position: absolute;
          z-index: 1;
          // right: 100px;
          width: 100px;
          height: 40px;
          border-radius: 8px;
          transition: all 300ms ease-out;
          background-image: linear-gradient(310deg,
              rgba(121, 40, 202, 0.85),
              rgba(255, 0, 128, 0.85));
          box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, 0.9),
            0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
        }

        .active {
          color: #fff;
        }

        &_type {
          cursor: pointer;
          padding: 0 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 40px;
          z-index: 2;
        }
      }
    }

    &_content {
      margin-top: 20px;
      height: 300px;
      display: flex;
      justify-content: space-between;

      &_left {
        width: 50%;
        height: 100%;
        border-radius: 12px;
        box-shadow: 0 0 68px 2px rgba(0, 0, 0, 0.1);

        &_l1 {
          padding: 0 15px;
          display: flex;
          align-items: center;
          width: 100%;
          height: 33%;

          div {
            width: 30%;
            color: rgb(103, 116, 142);
            font-size: 16px;
            text-align: center;
            letter-spacing: 1.5px;
          }

          input {
            width: 70%;
            font-size: 20px;
            margin-left: 12px;
            border: none;
            border-bottom: 1px solid rgb(103, 116, 142);
          }

          input:focus {
            outline: none;
            border-bottom: 2px solid rgb(203, 12, 159);
          }
        }
      }

      &_right {
        width: 46%;
        height: 100%;
        border-radius: 12px;
        box-shadow: 0 0 68px 2px rgba(0, 0, 0, 0.1);

        &_l1 {
          padding: 0 15px;
          display: flex;
          align-items: center;
          width: 100%;
          height: 33%;

          div {
            width: 30%;
            color: rgb(103, 116, 142);
            font-size: 16px;
            text-align: center;
            letter-spacing: 1.5px;
          }

          input {
            width: 70%;
            font-size: 26px;
            margin-left: 12px;
            border-radius: 5px;
            border: 1px solid #67748e;
            background: none;
          }

          input:focus {
            outline: none;
            border-bottom: 2px solid rgb(203, 12, 159);
          }
        }
      }
    }
  }
}

.comfirm_btn {
  cursor: pointer;
  width: 100px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.5px;
  border-radius: 4px;
  transition: all 300ms ease-out;
  background-image: linear-gradient(310deg, #141727 0%, #3a416f 100%);
}

.comfirm_btn:hover {
  transform: scale(1.05);
}
</style>
