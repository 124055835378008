import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './theme/index.css'
import './plugins/element.js'
import './assets/css/global.css'
import './assets/fonts/iconfont.css'
import axios from 'axios'
import Cookies from 'js-cookie'
import './plugins/directives.js'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import forge from './plugins/forge.min.js'
// 导入i18n
import i18n from './i18n/i18n';
// import './theme/dark/index.css';


Vue.config.productionTip = false
// 配置API请求域名
// 沙河环境
axios.defaults.baseURL = 'https://doc.limaopay.cn'
Vue.prototype.$domain = "doc.limaopay.cn"
// 正式环境
// axios.defaults.baseURL = 'https://cdn.limaopay.cn'
// Vue.prototype.$domain = "cdn.limaopay.cn"
// 防ddos
// axios.defaults.baseURL = 'https://limaopay.com'
// Vue.prototype.$domain = "limaopay.com"
Vue.prototype.$http = axios
Vue.prototype.$cookie = Cookies




NProgress.configure({
  easing: 'ease-in-out', // 动画方式，和css动画属性一样（默认：ease）
  speed: 1000, // 递增进度条的速度，单位ms（默认： 200）
  showSpinner: false, // 是否显示加载ico
  trickle: true, // 是否自动递增
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比，0-1（默认：0.08）
  parent: '#app' // 指定此选项以更改父容器（默认：body）
})

router.beforeEach((to, from, next) => {
  // 每次切换页面时，调用进度条
  NProgress.start()
  // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
  next()
})
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})

// 在request拦截器中显示进度条Nprogress.start()
axios.interceptors.request.use(config => {
  // 请求开始时显示进度条
  NProgress.start()
  return config
})
// 拦截器中
// axios.interceptors.response.use(
//   (response) => {
//     // 对响应数据做点什么
//     return response;
//   },
//   (error) => {
//     // 对响应错误做点什么
//     return Promise.reject(error);
//   }
// )
// response中完成进度条Nprogress.done()
axios.interceptors.response.use(config => {
  // 服务响应时完成进度条
  NProgress.done()
  return config
})

// 判断用户权限
Vue.prototype.VerifyToken = function (tokenStr) {
  console.log('tokenStr: ', tokenStr);
  if (tokenStr == '' || tokenStr == null) {
    console.log('未登录')
    // 判断用户IP
    let params = {};
    params = this.$lmjm(params)
    const result = axios.post('/api/v1/getipaddress', params, {
      headers: {
      }
    })
    result.then((res) => {
      if (res.data.data.in_china == 1 && res.data.data.iplimit == 1) {
        window.open('/error.html', '_self')
        // router.push('/login')
      } else {
        router.push('/login')
      }
    }, (error) => {
      console.log('错误:' + error)
    })
  } else {
    let params = {};
    params = this.$lmjm(params)
    const result = axios.post('/api/v1/getAuth', params, {
      headers: {
        token: tokenStr
      }
    })
    result.then((res) => {
      if (res.data.code === 200) {
        if (res.data.data.types === 1) {
          router.push('/home')
        } else if (res.data.data.types === 3) {
          router.push('/admin/home')
        } else if (res.data.data.types === 2) {
          router.push('/home')
        }
      }
    }, (error) => {
      console.log('错误:' + error)
    })
  }
}

Vue.directive('noMoreClick', {
  inserted(el, binding) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 5000)
    })
  }
})
Vue.directive('noClick', {
  inserted(el, binding) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 1)
    })
  }
})
Vue.prototype.$lmjm = function (data) {
  if (data == "") {
    data = {}
  }
  //获取随机nonce
  function createNonceStr() {
    return Math.random().toString(36).substr(2, 16);
  }


  // md5加密方法
  function md5encrypt(val) {
    let md = forge.md.md5.create();
    md.update(val);
    let undateVal = md.digest().toHex();
    return undateVal;
  }


  // AES加密
  // aes_key  加密的key
  // val  加密的字符串内容
  function aesencrypt(aes_key, val) {
    var cipher = forge.cipher.createCipher('AES-CBC', forge.util.decode64(aes_key));
    cipher.start({
      iv: forge.util.decode64(aes_key)
    });
    cipher.update(forge.util.createBuffer(val));
    cipher.finish();
    // outputs encrypted hex
    let aes_secret = forge.util.encode64(cipher.output.getBytes());
    return aes_secret;
  }

  // RSA加密方法
  function encryptRsa(encrypt_key, clearText) {
    //注意此处上下的BEGIN PRIVATE KEY不要删除，框架自带的
    const public_key = '-----BEGIN PUBLIC KEY-----\n' +
      encrypt_key +
      '\n-----END PUBLIC KEY-----';
    var publicKey = forge.pki.publicKeyFromPem(public_key);
    var buffer = forge.util.createBuffer(clearText, 'utf8');
    var bytes = buffer.getBytes();
    var encryptedText = forge.util.encode64(publicKey.encrypt(bytes, 'RSAES-PKCS1-V1_5', {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha1.create()
      }
    }));
    return encryptedText;
  };



  // 参数构造方法
  // aes_key  aes加密的key，这个是动态的，由后端来决定，对接的时候写死，上线的时候向后端请求获取，并且每天凌晨变化一次
  // jsondata {} 对象，向后端传递的所有参数param,不放进来token
  // RSA加密方法，加密后返回原对象数据并新增了timestmap和sign属性
  function gouzaoparame(aes_key, jsondata) {

    // RSA公钥放到全局配置文件当中
    let key =
      'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlYi/TUHKoJpgoHIShu7R4FIRYsJxCjHZ9Au+ITrEjES1Hmf+vp+8J2/ku8whL0vHW45V6Yayu9oBtR2zNbisrmeyDaRh2qYpwyUt4Vxvz65r2aiakxtNj8mYgmfxd5qsN/jOTmpKsyuYnBqPySbLW21sb9jH9wKSMW/WIUO5M9+GpsqtUFrfTJrj1g9hTRINNwqsIcqJdgD3j9RA88Ove27S1QoIGtlwi5Ofazze96rviXOAeoZ95PoWwkBpDb4auscQv++esEZuI9+XIowleQu7T+xxAfwIyWMB+AtCQExYpTGGZV1gDq/yMp81br+0I0v0EjOva5X08DVWRkDlcQIDAQAB';

    let ttt = Math.ceil(new Date().getTime() / 1000);
    jsondata.timestmap = ttt;
    jsondata.noncestr = aesencrypt(aes_key, createNonceStr())
    jsondata.key = aesencrypt(aes_key, "limaozhandian")
    let sortData = "";
    let indd = 0;
    Object.keys(jsondata).sort().map(key => {
      sortData += key + "=" + jsondata[key]
      if (Object.keys(jsondata).length - 1 != indd) {
        sortData += "&";
      }
      indd++;
    })

    let sign = encryptRsa(key, md5encrypt(window.btoa(unescape(encodeURIComponent(sortData)))));
    jsondata.sign = sign;
    return jsondata;
  }

  // aes_key
  let aes_key = "gFy5IV0g55QlfS28VbvQ6A==";
  return gouzaoparame(aes_key, data)
}
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
