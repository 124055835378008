<template>
  <div class="reg_container">
    <el-row>
      <el-col :span="16">
        <div class="imgContainer">
          <img class="bg_dw" src="../images/04_dingwei.png" />
          <img class="bg_eg1" src="../images/04_eg1.png" />
          <img class="bg_eg2" src="../images/04_eg2.png" />
          <img class="bg_eg3" src="../images/04_eg3.png" />
        </div>
      </el-col>
      <el-col :span="8">
        <div class="reg_box">
          <div class="reg_title">
            {{ $t("register.signUpForYourInstantAccount") }}
          </div>
          <el-form :model="form_reg" :rules="regFormRules" ref="form_regFormRef">
            <!-- 用户名 -->
            <div style="width: 20%; display: inline-block" id="gjcodes">
              <el-select v-model="gjcodes" :placeholder="$t('register.country')">
                <el-option style="height: 55px" v-for="item in gjda" :key="item.CNName"
                  :label="'+' + item.dialCode + '  ' + item.CNName" :value="item.dialCode">
                </el-option>
              </el-select>
            </div>
            <div style="width: 75%; display: inline-block; margin-left: 5%">
              <el-form-item prop="phonenumber">
                <el-input class="mysearch" v-model="form_reg.phonenumber" show-word-limit maxlength="11"
                  prefix-icon="iconfont icon-user" :placeholder="$t('register.des1')"></el-input>
              </el-form-item>
            </div>
            <!-- 密码 -->
            <el-form-item prop="password">
              <el-input class="mysearch" v-model="form_reg.password" prefix-icon="iconfont icon-3702mima"
                type="password" show-password :placeholder="$t('register.des2')"></el-input>
            </el-form-item>
            <!-- 再次输入密码 -->
            <el-form-item prop="Rpassword">
              <el-input class="mysearch" v-model="form_reg.Rpassword" prefix-icon="iconfont icon-3702mima"
                type="password" show-password :placeholder="$t('register.des3')"></el-input>
            </el-form-item>
            <!-- 输入邮箱 -->
            <!-- <el-form-item prop="email">
              <el-input class="mysearch emailinput" v-model="form_reg.email" prefix-icon="el-icon-message"
                :placeholder="$t('register.des9')">
              </el-input>
            </el-form-item> -->
            <!-- 邀请码 -->
            <el-form-item prop="inviteCode">
              <el-input class="mysearch" v-model="form_reg.inviteCode" prefix-icon="el-icon-share"
                :placeholder="$t('register.des4')">
              </el-input>
            </el-form-item>
            <el-form-item prop="realname">
              <el-input class="mysearch" v-model="form_reg.realname" prefix-icon="iconfont icon-user"
                :placeholder="$t('register.des5')"></el-input>
            </el-form-item>
            <el-form-item prop="realcard">
              <el-input class="mysearch" v-model="form_reg.realcard" minlength="18" show-word-limit maxlength="18"
                prefix-icon="el-icon-bank-card" :placeholder="$t('register.des6')"></el-input>
            </el-form-item>
            <el-form-item prop="code" class="codeInput">
              <el-input class="mysearch" style="width: 58%; margin-top: 9px" v-model="form_reg.code"
                prefix-icon="el-icon-picture" :placeholder="$t('register.des7')"></el-input>
              <img class="codeImg" :src="codeSrc" @click="getCodeUrl()" />
            </el-form-item>
            <!-- 注册按钮 -->
            <el-form-item>
              <el-button type="primary" v-no-more-click @click="register()">{{
                $t("register.enroll")
              }}</el-button>
            </el-form-item>
          </el-form>
          <!-- 用户协议 -->
          <div class="reg_right">
            <el-checkbox size="medium" v-model="agr"></el-checkbox>
            {{ $t("register.readAndAgree") }}
            <!-- 注册协议 -->
            <el-link style="margin-left: 0px; font-size: 15px; margin-bottom: 5px" type="primary"
              @click="centerDialogVisible = true">
              《{{ $t("register.registrationAgreement") }}》
            </el-link>
            <!-- 隐私协议 -->
            <el-link style="margin-left: 0px; font-size: 15px; margin-bottom: 5px" type="primary"
              @click="ysVisible = true">
              《{{ $t("register.privacyAgreement") }}》
            </el-link>
            <!-- 保密协议 -->
            <el-link style="margin-left: 0px; font-size: 15px; margin-bottom: 5px" type="primary"
              @click="tkVisible = true">
              《{{ $t("register.refundAgreement") }}》
            </el-link>
          </div>
          <div class="reg_left">
            <el-link type="primary" @click="go_login()" style="font-size: 15px; margin-bottom: 6px">{{
              $t("register.logInNow") }}
              <svg class="pzh" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16"
                height="16" viewBox="0 0 16 16" fill="none">
                <defs>
                  <rect id="path_0" x="0" y="0" width="16" height="16"></rect>
                </defs>
                <g opacity="1" transform="translate(0 0)  rotate(0 8 8)">
                  <rect fill="currentColor" opacity="1" transform="translate(0 0)  rotate(0 8 8)" x="0" y="0" width="16"
                    height="16"></rect>
                  <mask id="bg-mask" fill="white">
                    <use xlink:href="#path_0"></use>
                  </mask>
                  <g mask="url(#bg-mask)">
                    <path id="路径 23" style="
                        stroke: #f3f7fb;
                        stroke-width: 1.4;
                        stroke-opacity: 1;
                        stroke-dasharray: 0 0;
                      " transform="translate(6.055555555555657 4.5)  rotate(0 1.9444444444444446 3.5)"
                      d="M0,0L3.89,3.5L0,7 "></path>
                  </g>
                </g>
              </svg></el-link>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="centerDialogVisible" width="50%" center top="5vh">
      <div class="logo">
        <img src="../images/chip.png" alt="" />
        <div class="companyName">LI MAO INC</div>
      </div>
      <!-- <div class="content-title" v-if="this.$i18n.locale == 'hk'">註冊協議</div>
      <div class="content-title" v-if="this.$i18n.locale == 'cn'">注册协议</div> -->
      <div class="content-title">
        Registration Agreement
      </div>
      <!-- <div style="height: 60vh; overflow-y: auto" v-if="this.$i18n.locale == 'hk'">
        <h2>
          本協議是您與貍貓（簡稱「本平臺」）所有者之間就本平臺服務等相關事宜所訂立的契約，請您仔細閱讀本註冊協議，您同意本協議，本協議即構成對雙方有約束力的法律文件。
        </h2>
        <h3>第1條 本平臺服務條款的確認和接納</h3>
        <ul>
          <li>
            1.1
            本平臺的各項電子服務的所有權和運作權歸本平臺所有。用戶同意所有註冊協議條款並完成註冊程序，才能成為本平臺的正式用戶。用戶確認：本協議條款是處理雙方權利義務的契約，始終有效，法律另有強製性規定或雙方另有特別約定的，依其規定。
          </li>
          <li>
            1.2
            用戶點擊同意本協議的，即視為用戶確認自己具有享受本平臺服務、下單購物等相應的權利能力和行為能力，能夠獨立承擔法律責任。
          </li>
          <li>
            1.3
            如果您在18周歲以下，則只能在父母或監護人的監護參與下才能使用本平臺。若您不具備該主體資格，則您的監護人應承擔因此而導致的一切後果，且本平臺有權註銷或永久凍結您的賬戶，並向您及其監護人索償相應損失。（目前暫不為18周歲以下群體開放）
          </li>
          <li>
            1.4
            本平臺保留在中華人民共和國大陸地區法施行之法律允許的範圍內獨自決定拒絕服務、關閉用戶賬戶、清除或編輯內容或取消訂單的權利。
          </li>
        </ul>
        <h3>第2條 本平臺服務</h3>
        <ul>
          <li>
            2.1
            本平臺通過互聯網依法為用戶提供互聯網信息等服務，用戶在完全同意本協議及本平臺規定的情況下，方有權使用本平臺的相關服務。
          </li>
          <li>2.2 用戶必須自行準備如下設備和承擔如下開支：</li>
          <ul>
            <li>
              （1）上網設備，包括並不限於電腦或者其他上網終端、調製解調器及其他必備的上網裝置；
            </li>
            <li>
              （2）上網開支，包括並不限於網絡接入費、上網設備租用費、手機流量費等。
            </li>
          </ul>
        </ul>
        <h3>第3條 用戶信息</h3>
        <ul>
          <li>
            3.1
            用戶應自行誠信向本平臺提供註冊資料，用戶同意其提供的註冊資料真實、準確、完整、合法有效，用戶註冊資料如有變動的，應及時更新其註冊資料。如果用戶提供的註冊資料不合法、不真實、不準確、不詳盡的，用戶需承擔因此引起的相應責任及後果，並且本平臺保留終止用戶使用本平臺各項服務的權利。
          </li>
          <li>
            3.2
            用戶在本平臺進行瀏覽、下單購物等活動時，涉及用戶真實姓名/名稱、通信地址、聯系電話、電子郵箱等隱私信息的，本平臺將予以嚴格保密，除非得到用戶的授權或法律另有規定，本平臺不會向外界披露用戶隱私信息。
          </li>
          <li>
            3.3
            用戶註冊成功後，將產生用戶名和密碼等賬戶信息，您可以根據本平臺規定改變您的密碼。用戶應謹慎合理的保存、使用其用戶名和密碼。用戶若發現任何非法使用用戶賬號或存在安全漏洞的情況，請立即通知本平臺並向公安機關報案。
          </li>
          <li>
            3.4
            用户同意，本平台拥有通过邮件、短信电话等形式，向在本平台注册、购物用户、发送订单信息、促销活动等告知信息的权利。
          </li>
          <li>
            3.5
            用戶同意，本平臺擁有通過郵件、短信電話等形式，向在本平臺註冊、購物用戶、發送訂單信息、促銷活動等告知信息的權利。
          </li>
          <li>
            3.6
            用戶同意，本平臺有權使用用戶的註冊信息、用戶名、密碼等信息，登錄進入用戶的註冊賬戶，進行證據保全，包括但不限於公證、見證等。
          </li>
        </ul>
        <h3>第4條 用戶依法言行義務</h3>
        <ul>
          <li>
            4.1本協議依據國家相關法律法規規章製定，用戶同意嚴格遵守以下義務：
          </li>
          <ul>
            <li>
              （1）不得傳輸或發表：煽動抗拒、破壞憲法和法律、行政法規實施的言論，煽動顛覆國家政權，推翻社會主義製度的言論，煽動分裂國家、破壞國家統一的的言論，煽動民族仇恨、民族歧視、破壞民族團結的言論；
            </li>
            <li>（2）從中國大陸向境外傳輸資料信息時必須符合中國有關法規；</li>
            <li>
              （3）不得利用本平臺從事洗錢、竊取商業秘密、竊取個人信息等違法犯罪活動；
            </li>
            <li>
              （4）不得幹擾本平臺的正常運轉，不得侵入本平臺及國家計算機信息系統；
            </li>
            <li>
              （5）不得傳輸或發表任何違法犯罪的、騷擾性的、中傷他人的、辱罵性的、恐嚇性的、傷害性的、庸俗的，淫穢的、不文明的等信息資料；
            </li>
            <li>
              （6）不得傳輸或發表損害國家社會公共利益和涉及國家安全的信息資料或言論；
            </li>
            <li>（7）不得教唆他人從事本條所禁止的行為；</li>
            <li>
              （8）不得利用在本平臺註冊的賬戶進行牟利性經營活動、列如倒賣虛擬卡產品行為；
            </li>
            <li>
              （9）不得發布任何侵犯他人著作權、商標權等知識產權或合法權利的內容；
            </li>
            <li>
              （10）不得使用虛擬卡從事退款、買退、撤銷、驗證、薅羊毛、收款、政務、法律機構、信貸機構、成人色情、賭博網站等違法行為；
            </li>
            <li>
              （11）不得使用虛擬卡用於任何非法操作例如:不得用於Airbnb、Lyft、Uber等類似平臺的詐騙行為和其他非法交易、一經發現有上述違規交易行為，系統將作廢卡片並關閉賬戶且不退回任何資金、請務必規範用卡；
            </li>
          </ul>
          <li>4.2 用戶應關註並遵守本平臺公布或修改的各類合法規則規定。</li>
          <li>
            4.3
            本平臺保有刪除平臺內各類不符合法律政策或不真實的信息內容而無須通知用戶的權利。
          </li>
          <li>
            4.4
            若用戶未遵守以上規定的，本平臺有權作出獨立判斷並采取暫停或關閉用戶帳號等措施。用戶須對自己在網上的言論和行為承擔法律責任。
          </li>
        </ul>

        <h3>第5條 商品信息</h3>
        <ul>
          <li>
            5.1本平臺上的商品價格、數量、是否有貨等商品信息隨時都有可能發生變動，本平臺不作特別通知。由於本平臺上商品信息的數量極其龐大，雖然本平臺會盡最大努力保證您所瀏覽商品信息的準確性，但由於眾所周知的互聯網技術因素等客觀原因存在，本平臺網頁顯示的信息可能會有一定的滯後性或差錯，對此情形您知悉並理解；本平臺歡迎糾錯，並會視情況給予糾錯者一定的獎勵。
            為表述便利，商品和服務簡稱為「商品」或「服務」。
          </li>
        </ul>
        <h3>第6條 訂單</h3>
        <ul>
          <li>
            6.1
            在您下訂單時，請您仔細確認所購商品的名稱、價格、數量。被服務人與用戶本人不一致的，被服務人的行為和意思表示視為用戶的行為和意思表示。
          </li>
          <li>
            6.2
            除法律另有強製性規定外，雙方約定如下：本平臺上銷售方展示的商品和價格等信息僅僅是交易信息的發布，您下單時須填寫您希望購買的商品數量、價款及支付方式、用戶、聯系方式等內容；系統生成的訂單信息是計算機信息系統根據您填寫的內容自動生成的數據，僅是您向銷售方發出的交易訴求；銷售方收到您的訂單信息後，只有在銷售方將您在訂單中訂購的商品實際直接向您發出時，方視為您與銷售方之間就實際直接向您發出的商品建立了交易關系；如果您在一份訂單裏訂購了多種商品並且銷售方只給您發出了部分商品時，您與銷售方之間僅就實際直接向您發出的商品建立了交易關系；只有在銷售方實際直接向您發出了訂單中訂購的其他商品時，您和銷售方之間就訂單中該其他已實際直接向您發出的商品才成立交易關系。您可以隨時登錄您在本平臺註冊的賬戶，查詢您的訂單狀態。
          </li>
          <li>
            6.3
            由於市場變化及盡商業上的合理努力難以控製的因素的影響，我們不提供關於任何網站的使用下單教程，也不保證一定能用於某個特定網站如您需要測試卡段請合理安排用卡數量。成功購買的虛擬卡無法做卡號變更也無法申請退款。
          </li>
        </ul>
        <h3>第7條 所有權及知識產權條款</h3>
        <ul>
          <li>
            7.1
            用戶同意並已充分了解本協議的條款，承諾不將已發表於本平臺的信息，以任何形式發布或授權其它主體以任何方式使用（包括但不限於在各類平臺、媒體上使用）。
          </li>
          <li>
            7.2
            本平臺是本平臺的製作者，擁有此本平臺內容及資源的著作權等合法權利，受國家法律保護，有權不時地對本協議及本平臺的內容進行修改，並在本平臺張貼，無須另行通知用戶。在法律允許的最大限度範圍內，本平臺對本協議及本平臺內容擁有解釋權。
          </li>
          <li>
            7.3
            除法律另有強製性規定外，未經本平臺明確的特別書面許可，任何單位或個人不得以任何方式非法地全部或部分復製、轉載、引用、鏈接、抓取或以其他方式使用本平臺的信息內容，否則，本平臺有權追究其法律責任。
          </li>
          <li>
            7.4
            本平臺所刊登的資料信息（諸如文字、圖表、標識、按鈕圖標、圖像、聲音文件片段、數字下載、數據編輯和軟件），均是本平臺或其內容提供者的財產，受中國和國際版權法的保護。本平臺上所有內容的匯編是本平臺的排他財產，受中國和國際版權法的保護。本平臺上所有軟件都是本平臺或其關聯公司或其軟件供應商的財產，受中國和國際版權法的保護。
          </li>
        </ul>
        <h3>第8條 責任限製及不承諾擔保</h3>
        <ul>
          <li>
            8.1
            除非另有明確的書面說明，本平臺及其所包含的或以其它方式通過本平臺提供給您的全部信息、內容、產品（包括軟件）和服務，均是在「按現狀」和「按現有」的基礎上提供的。
          </li>
          <li>
            8.2
            除非另有明確的書面說明，本平臺不對本平臺的運營及其包含在本平臺上的信息、內容、產品（包括軟件）或服務作任何形式的、明示或默示的聲明或擔保（根據中華人民共和國法律另有規定的以外）。
          </li>
          <li>
            8.3
            本平臺不擔保本平臺所包含的或以其它方式通過本平臺提供給您的全部信息、內容、產品（包括軟件）和服務、其服務器或從本平臺發出的電子信件、信息沒有病毒或其他有害成分。
          </li>
          <li>
            8.4
            如因不可抗力或其它本平臺無法控製的原因使本平臺銷售系統崩潰或無法正常使用導致網上交易無法完成或丟失有關的信息、記錄等，本平臺會合理地盡力協助處理善後事宜。
          </li>
        </ul>
        <h3>第9條 協議更新及用戶關註義務</h3>
        <ul>
          <li>
            9.1
            根據國家法律法規變化及平臺運營需要，本平臺有權對本協議條款不時地進行修改，修改後的協議一旦被張貼在本平臺上即生效，並代替原來的協議。用戶可隨時登錄查閱最新協議；用戶有義務不時關註並閱讀最新版的協議及平臺公告。如用戶不同意更新後的協議，可以且應立即停止接受本平臺依據本協議提供的服務；如用戶繼續使用本平臺提供的服務的，即視為同意更新後的協議。本平臺建議您在使用本平臺之前閱讀本協議及本平臺的公告。如果本協議中任何一條被視為廢止、無效或因任何理由不可執行，該條應視為可分的且並不影響任何其余條款的有效性和可執行性。
          </li>
        </ul>
        <h3>第10條 法律管轄和適用</h3>
        <ul>
          <li>
            10.1
            本協議的訂立、執行和解釋及爭議的解決均應適用在中華人民共和國大陸地區適用之有效法律（但不包括其沖突法規則）。如發生本協議與適用之法律相抵觸時，則這些條款將完全按法律規定重新解釋，而其它有效條款繼續有效。如締約方就本協議內容或其執行發生任何爭議，雙方應盡力友好協商解決；協商不成時，在爭議裏需要郵寄的商品任何一方均可向有管轄權的中華人民共和國境內爭議雙方的所在地法院提起訴訟，網絡虛擬類物品，計算機軟件（遊戲激活號，數字產品等）請於本所在地人民法院提起訴訟。
          </li>
        </ul>
        <h3>第11條 免責條款</h3>
        <ul>
          <li>
            11.1
            因銀行等金融機構或組織、政府行為、監管政策、導致卡段失效等不可抗力的情況導致賬戶及卡使用受影響，本系統不承擔相關的連帶責任和相關損失。
          </li>
          <li>
            11.2
            本系統僅提供用卡服務，用戶不得倒賣及非法使用，不然產生一切後果，與本公司無關。
          </li>
          <li>
            11.3
            本平臺尊重用戶和消費者的合法權利，本協議及本上發布的各類規則、聲明等其他內容，均是為了更好的、更加便利的為用戶和消費者提供服務。本平臺歡迎用戶和社會各界提出意見和建議，本平臺將虛心接受並適時修改本協議及本平臺上的各類規則。
          </li>
        </ul>
        <h3>第12條 承諾條款</h3>
        <ul>
          <li>
            12.1
            您在本平臺進行註冊、登錄即視為您完全接受本協議，請您再次確認已知悉並完全理解本協議的全部內容。
          </li>
        </ul>
        <p>
          如您開始使用本系統即代表您知曉並同意上述用戶協議，並同意在此協議基礎上開展合作。
        </p>
      </div>
      <div style="height: 60vh; overflow-y: auto" v-if="this.$i18n.locale == 'cn'">
        <h2>
          本协议是您与狸猫（简称“本平台”）所有者之间就本平台服务等相关事宜所订立的契约，请您仔细阅读本注册协议，您同意本协议，本协议即构成对双方有约束力的法律文件。
        </h2>
        <h3>第1条 本平台服务条款的确认和接纳</h3>
        <ul>
          <li>
            1.1
            本平台的各项电子服务的所有权和运作权归本平台所有。用户同意所有注册协议条款并完成注册程序，才能成为本平台的正式用户。用户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。
          </li>
          <li>
            1.2
            用户点击同意本协议的，即视为用户确认自己具有享受本平台服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。
          </li>
          <li>
            1.3
            如果您在18周岁以下，则只能在父母或监护人的监护参与下才能使用本平台。若您不具备该主体资格，则您的监护人应承担因此而导致的一切后果，且本平台有权注销或永久冻结您的账户，并向您及其监护人索偿相应损失。（目前暂不为18周岁以下群体开放）
          </li>
          <li>
            1.4
            本平台保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。
          </li>
        </ul>
        <h3>第2条 本平台服务</h3>
        <ul>
          <li>
            2.1
            本平台通过互联网依法为用户提供互联网信息等服务，用户在完全同意本协议及本平台规定的情况下，方有权使用本平台的相关服务。
          </li>
          <li>2.2 用户必须自行准备如下设备和承担如下开支：</li>
          <ul>
            <li>
              （1）上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；
            </li>
            <li>
              （2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。
            </li>
          </ul>
        </ul>
        <h3>第3条 用户信息</h3>
        <ul>
          <li>
            3.1
            用户应自行诚信向本平台提供注册资料，用户同意其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且本平台保留终止用户使用本平台各项服务的权利。
          </li>
          <li>
            3.2
            用户在本平台进行浏览、下单购物等活动时，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，本平台将予以严格保密，除非得到用户的授权或法律另有规定，本平台不会向外界披露用户隐私信息。
          </li>
          <li>
            3.3
            用户注册成功后，将产生用户名和密码等账户信息，您可以根据本平台规定改变您的密码。用户应谨慎合理的保存、使用其用户名和密码。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知本平台并向公安机关报案。
          </li>
          <li>
            3.4
            用户同意，本平台拥有通过邮件、短信电话等形式，向在本平台注册、购物用户、发送订单信息、促销活动等告知信息的权利。
          </li>
          <li>
            3.5
            用户同意，本平台拥有通过邮件、短信电话等形式，向在本平台注册、购物用户、发送订单信息、促销活动等告知信息的权利。
          </li>
          <li>
            3.6
            用户同意，本平台有权使用用户的注册信息、用户名、密码等信息，登录进入用户的注册账户，进行证据保全，包括但不限于公证、见证等。
          </li>
        </ul>
        <h3>第4条 用户依法言行义务</h3>
        <ul>
          <li>
            4.1本协议依据国家相关法律法规规章制定，用户同意严格遵守以下义务：
          </li>
          <ul>
            <li>
              （1）不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；
            </li>
            <li>（2）从中国大陆向境外传输资料信息时必须符合中国有关法规；</li>
            <li>
              （3）不得利用本平台从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；
            </li>
            <li>
              （4）不得干扰本平台的正常运转，不得侵入本平台及国家计算机信息系统；
            </li>
            <li>
              （5）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；
            </li>
            <li>
              （6）不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；
            </li>
            <li>（7）不得教唆他人从事本条所禁止的行为；</li>
            <li>
              （8）不得利用在本平台注册的账户进行牟利性经营活动、列如倒卖虚拟卡产品行为；
            </li>
            <li>
              （9）不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；
            </li>
            <li>
              （10）不得使用虚拟卡从事退款、买退、撤销、验证、薅羊毛、收款、政务、法律机构、信贷机构、成人色情、赌博网站等违法行为；
            </li>
            <li>
              （11）不得使用虚拟卡用于任何非法操作例如:不得用于Airbnb、Lyft、Uber等类似平台的诈骗行为和其他非法交易、一经发现有上述违规交易行为，系统将作废卡片并关闭账户且不退回任何资金、请务必规范用卡；
            </li>
          </ul>
          <li>4.2 用户应关注并遵守本平台公布或修改的各类合法规则规定。</li>
          <li>
            4.3
            本平台保有删除平台内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。
          </li>
          <li>
            4.4
            若用户未遵守以上规定的，本平台有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。
          </li>
        </ul>

        <h3>第5条 商品信息</h3>
        <ul>
          <li>
            5.1本平台上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，本平台不作特别通知。由于本平台上商品信息的数量极其庞大，虽然本平台会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，本平台网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；本平台欢迎纠错，并会视情况给予纠错者一定的奖励。
            为表述便利，商品和服务简称为“商品”或“服务”。
          </li>
        </ul>
        <h3>第6条 订单</h3>
        <ul>
          <li>
            6.1
            在您下订单时，请您仔细确认所购商品的名称、价格、数量。被服务人与用户本人不一致的，被服务人的行为和意思表示视为用户的行为和意思表示。
          </li>
          <li>
            6.2
            除法律另有强制性规定外，双方约定如下：本平台上销售方展示的商品和价格等信息仅仅是交易信息的发布，您下单时须填写您希望购买的商品数量、价款及支付方式、用户、联系方式等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向销售方发出的交易诉求；销售方收到您的订单信息后，只有在销售方将您在订单中订购的商品实际直接向您发出时，方视为您与销售方之间就实际直接向您发出的商品建立了交易关系；如果您在一份订单里订购了多种商品并且销售方只给您发出了部分商品时，您与销售方之间仅就实际直接向您发出的商品建立了交易关系；只有在销售方实际直接向您发出了订单中订购的其他商品时，您和销售方之间就订单中该其他已实际直接向您发出的商品才成立交易关系。您可以随时登录您在本平台注册的账户，查询您的订单状态。
          </li>
          <li>
            6.3
            由于市场变化及尽商业上的合理努力难以控制的因素的影响，我们不提供关于任何网站的使用下单教程，也不保证一定能用于某个特定网站如您需要测试卡段请合理安排用卡数量。成功购买的虚拟卡无法做卡号变更也无法申请退款。
          </li>
        </ul>
        <h3>第7条 所有权及知识产权条款</h3>
        <ul>
          <li>
            7.1
            用户同意并已充分了解本协议的条款，承诺不将已发表于本平台的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不限于在各类平台、媒体上使用）。
          </li>
          <li>
            7.2
            本平台是本平台的制作者，拥有此本平台内容及资源的著作权等合法权利，受国家法律保护，有权不时地对本协议及本平台的内容进行修改，并在本平台张贴，无须另行通知用户。在法律允许的最大限度范围内，本平台对本协议及本平台内容拥有解释权。
          </li>
          <li>
            7.3
            除法律另有强制性规定外，未经本平台明确的特别书面许可，任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本平台的信息内容，否则，本平台有权追究其法律责任。
          </li>
          <li>
            7.4
            本平台所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是本平台或其内容提供者的财产，受中国和国际版权法的保护。本平台上所有内容的汇编是本平台的排他财产，受中国和国际版权法的保护。本平台上所有软件都是本平台或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。
          </li>
        </ul>
        <h3>第8条 责任限制及不承诺担保</h3>
        <ul>
          <li>
            8.1
            除非另有明确的书面说明，本平台及其所包含的或以其它方式通过本平台提供给您的全部信息、内容、产品（包括软件）和服务，均是在“按现状”和“按现有”的基础上提供的。
          </li>
          <li>
            8.2
            除非另有明确的书面说明，本平台不对本平台的运营及其包含在本平台上的信息、内容、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
          </li>
          <li>
            8.3
            本平台不担保本平台所包含的或以其它方式通过本平台提供给您的全部信息、内容、产品（包括软件）和服务、其服务器或从本平台发出的电子信件、信息没有病毒或其他有害成分。
          </li>
          <li>
            8.4
            如因不可抗力或其它本平台无法控制的原因使本平台销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，本平台会合理地尽力协助处理善后事宜。
          </li>
        </ul>
        <h3>第9条 协议更新及用户关注义务</h3>
        <ul>
          <li>
            9.1
            根据国家法律法规变化及平台运营需要，本平台有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本平台上即生效，并代替原来的协议。用户可随时登录查阅最新协议；用户有义务不时关注并阅读最新版的协议及平台公告。如用户不同意更新后的协议，可以且应立即停止接受本平台依据本协议提供的服务；如用户继续使用本平台提供的服务的，即视为同意更新后的协议。本平台建议您在使用本平台之前阅读本协议及本平台的公告。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
          </li>
        </ul>
        <h3>第10条 法律管辖和适用</h3>
        <ul>
          <li>
            10.1
            本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，在争议里需要邮寄的商品任何一方均可向有管辖权的中华人民共和国境内争议双方的所在地法院提起诉讼，网络虚拟类物品，计算机软件（游戏激活号，数字产品等）请于本所在地人民法院提起诉讼。
          </li>
        </ul>
        <h3>第11条 免责条款</h3>
        <ul>
          <li>
            11.1
            因银行等金融机构或组织、政府行为、监管政策、导致卡段失效等不可抗力的情况导致账户及卡使用受影响，本系统不承担相关的连带责任和相关损失。
          </li>
          <li>
            11.2
            本系统仅提供用卡服务，用户不得倒卖及非法使用，不然产生一切后果，与本公司无关。
          </li>
          <li>
            11.3
            本平台尊重用户和消费者的合法权利，本协议及本上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。本平台欢迎用户和社会各界提出意见和建议，本平台将虚心接受并适时修改本协议及本平台上的各类规则。
          </li>
        </ul>
        <h3>第12条 承诺条款</h3>
        <ul>
          <li>
            12.1
            您在本平台进行注册、登录即视为您完全接受本协议，请您再次确认已知悉并完全理解本协议的全部内容。
          </li>
        </ul>
        <p>
          如您开始使用本系统即代表您知晓并同意上述用户协议，并同意在此协议基础上开展合作。
        </p>
      </div> -->
      <div style="height: 60vh; overflow-y: auto">
        <h2>
          By using our Website, you confirm that you accept these terms, and that you agree to comply with them. We
          recommend that you read these terms carefully (in particular section 6 on liability) and print a copy for
          future reference. If there is anything within these terms that you do not understand, please contact us using
          the details in section 1. If you do not agree to these terms, you must not use our Website.
        </h2>
        <h3>
          You warrant that you have capacity to, on behalf of your Business Firm, enter into these terms and complete
          the registration and know-your-customer documentation submission procedures on our Website and that you will
          only use our Website to apply for the Services on behalf of your Business Firm in a legitimate manner.
        </h3>
        <h3>
          We may amend these terms from time to time. We will note the date that amendments were last made at the top of
          these terms, and any amendments will take effect upon posting. Every time you wish to use our Website, please
          check these terms to ensure you understand the terms that apply at that time.
        </h3>
        <h3>
          1).RULES WHEN USING OUR WEBSITE
        </h3>
        <p>WE MAY MAKE CHANGES TO OUR WEBSITE </p>
        <ul>
          <li>
            We may update and change our Website from time to time to reflect changes to our Services, our users’ needs
            and our business priorities. We will try to give you reasonable notice of any major changes.
          </li>
        </ul>
        <p>WE MAY SUSPEND OR WITHDRAW OUR WEBSITE </p>
        <ul>
          <li>
            Our Website is made available free of charge.
          </li>
          <li>
            We do not guarantee that our Website, or any content on it, will always be available or be uninterrupted. We
            may suspend, withdraw, or restrict the availability of all or any part of our Website for business and
            operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.
          </li>
          <li>
            The provision of our Website is reliant on the internet and devices. You fully understand and agree that we
            will not be liable for any losses suffered by you as a result of our Website not being available due to
            events, circumstances or causes beyond our reasonable control, including but not limited to internet, system
            or device instability, computer viruses and hacker attacks.
          </li>
          <li>
            You are responsible for providing the necessary equipment (including but not limited to an appropriate
            device and internet connection) and ensuring that all persons who access our Website through your internet
            connection are aware of these terms and other applicable terms and conditions, and that they comply with
            them.
          </li>
        </ul>
        <p>YOUR ACCOUNT DETAILS</p>
        <ul>
          <li>
            You warrant that all account information supplied by you on behalf of your Business Firm is true, accurate,
            current and complete.
          </li>
          <li>
            If you choose, or you are provided with, a user identification code, password or any other piece of
            information as part of our security procedures, you must treat such information as confidential. You must
            not disclose it to any third party. You fully understand and agree that we will not be liable for any losses
            suffered by you or your Business Firm as a result of you failing to treat such information as confidential.
          </li>
          <li>
            We have the right to disable any user identification code or password, whether chosen by you or allocated by
            us, at any time, if in our reasonable opinion you or your Business Firm has failed to comply with any of
            these terms.
          </li>
          <li>
            If you or your Business Firm knows or suspects that anyone other than you or your Business Firm knows your
            user identification code or password, you must promptly notify us using the contact details in section 1 of
            these terms.
          </li>
        </ul>
        <p>HOW YOU MAY USE MATERIAL ON OUR WEBSITE </p>
        <ul>
          <li>
            We are the owner or the licensee of all patents, copyright, trademarks, business names and domain names,
            rights in designs, rights in computer software, database rights and all other intellectual property rights,
            in each case whether registered or unregistered, in our Website. All such rights are reserved.
          </li>
          <li>
            Neither you nor your Business Firm may modify the paper or digital copies of any materials you or your
            Business Firm has printed off or downloaded in any way, and neither you nor your Business Firm may use any
            illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
          </li>
          <li>
            Our status (and that of any identified contributors) as the authors of content on our Website must always be
            acknowledged.
          </li>
          <li>
            Neither you nor your Business Firm may use any part of the content on our Website for commercial purposes
            without obtaining a licence to do so from us or our licensors.
          </li>
          <li>
            If you or your Business Firm prints off, copies or downloads any part of our Website in breach of these
            terms, your and your Business Firm’s rights to use our Website will cease immediately and you and your
            Business Firm must, at our option, return or destroy any copies of the materials you have made.
          </li>
        </ul>
        <p>WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO</p>
        <ul>
          <li>
            Where our Website contains links to other sites and resources provided by third parties, these links are
            provided for your information only. Such links should not be interpreted as approval by us of those linked
            websites or information you may obtain from them.
          </li>
          <li>
            We have no control over the contents of those sites or resources.
          </li>
        </ul>
        <p>PROHIBITED USES</p>
        <ul>
          <li>
            <p>You and your Business Firm may only use our Website for lawful purposes. Neither you nor your Business
              Firm may use our Website:</p>
            <ul>
              <li> In any way that breaches any applicable local, national or international law or regulation.</li>
              <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</li>
              <li>For the purpose of harming or attempting to harm minors in any way.</li>
              <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional
                material or any other form of similar solicitation (spam).</li>
              <li>To knowingly transmit any data, send or upload any material that contains viruses, trojan horses,
                worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer
                code designed to adversely affect the operation of any computer software or hardware.</li>
            </ul>
            <p>You and your Business Firm also agree:</p>
            <ul>
              <li>Not to reproduce, duplicate, copy or re-sell any part of our Website in contravention with these
                terms.</li>
              <li>Not to access without authority, interfere with, damage or disrupt:</li>
              <ul>
                <li>any part of our Website;</li>
                <li>any equipment or network on which our Website is stored;</li>
                <li>any software used in the provision of our Website; or</li>
                <li>any equipment or network or software owned or used by any third party.</li>
              </ul>
              <li>
                Not to interfere or attempt to interfere with the normal operation of our Website or any activity that
                is conducted on our Website.
              </li>
              <li>
                Not to take any action that would result in an unreasonably large data load on our Website’s network.
              </li>
            </ul>
          </li>
        </ul>
        <p>WE ARE NOT RESPONSIBLE FOR VIRUSES AND YOU MUST NOT INTRODUCE THEM</p>
        <ul>
          <li>
            We do not guarantee that our Website will be secure or free from bugs or viruses.
          </li>
          <li>
            You and your Business Firm are responsible for configuring your information technology, computer programmes
            and platform to access our Website. You and your Business Firm should use your own virus protection
            software.
          </li>
          <li>
            Neither you nor your Business Firm may misuse our Website by knowingly introducing viruses, trojans, worms,
            logic bombs or other material that is malicious or technologically harmful. Neither you nor your Business
            Firm may attempt to gain unauthorised access to our Website, the server on which our Website is stored or
            any server, computer or database connected to our Website. Neither you nor your Business Firm may attack our
            Website via a denial-of-service attack or a distributed denial-of service attack.
          </li>
        </ul>
        <p>RULES ABOUT LINKING TO OUR WEBSITE</p>
        <ul>
          <li>
            You and your Business Firm may link to our home page, provided you and your Business Firm do so in a way
            that is fair and legal and does not damage our reputation or take advantage of it.
          </li>
          <li>
            Neither you nor your Business Firm may establish a link in such a way as to suggest any form of association,
            approval or endorsement on our part where none exists.
          </li>
          <li>
            Neither you nor your Business Firm may establish a link to our Website in any website that is not owned by
            you or your Firm (as applicable).
          </li>
          <li>
            Our Website must not be framed on any other site, nor may you or your Business Firm create a link to any
            part of our Website other than the home page.
          </li>
          <li>
            We reserve the right to withdraw linking permission without notice.
          </li>
        </ul>
        <p>Financial Crime Compliance</p>
        <ul>
          <li>
            <p>We are required to act in accordance with applicable laws, regulations, policies (including our policies)
              and request of statutory and regulatory authorities operating in various jurisdictions. These relate,
              amongst other things, to the prevention of money laundering, terrorist financing, bribery, corruption,
              actual or attempted tax evasion, fraud and the provision of financial or other services to any persons
              which may be subject to sanctions. We may in our absolute discretion take any action as we consider
              appropriate to comply with all such laws, regulations, policies and requests. Such action may include but
              is not limited to:</p>
            <ul>
              <li>
                screening, intercepting and investigating any instruction, drawdown request, application for services,
                payment or communication sent to or by you or your Business Firm (or on your or your Business Firm’s
                behalf) and to or from your or your Business Firm’s account;
              </li>
              <li>
                investigating and making further enquiries as to the source of or intended recipient of funds, the
                status and identity of a person or entity, whether they are subject to a sanction regime, and whether a
                name which might refer to a sanctioned person actually refers to that person;
              </li>
              <li>
                combining and using information about you or your Business Firm, your personal data, beneficial owners,
                authorised signatories and other representatives, accounts, transactions, use of our services with other
                related information possessed by us or our affiliates;
              </li>
              <li>
                delaying, blocking, suspending or refusing to process any payment or instruction to you or your Business
                Firm or by you or your Business Firm in our absolute discretion;
              </li>
              <li>
                refusing to enter or conclude transactions involving certain persons or entities;
              </li>
              <li>
                terminating our relationship with you or your Business Firm;
              </li>
              <li>
                reporting suspicious transactions to any authority; and
              </li>
              <li>
                taking any other actions necessary for us or our affiliates to meet any legal, regulatory or compliance
                obligations.
              </li>
            </ul>
          </li>

          <li>
            To the extent permissible by law, neither us nor any of our agents shall be liable for any loss (whether
            direct or consequential and including without limitation, loss of profit or interest) or damage suffered by
            you or your Business Firm or any third party, caused in whole or in part in connection with Financial Crime
            Compliance. For the purpose of this section 4, “Financial Crime Compliance” means any action to meet the
            compliance obligations relating to detection or prevention of financial crime that we may take.
          </li>

        </ul>
        <h3>
          2).HOW WE MAY USE YOUR PERSONAL INFORMATION
        </h3>
        <p>We will only use your personal information as set out in our Privacy Policy.</p>
        <h3>3).LIABILITY</h3>
        <p>
          OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU
        </p>
        <ul>
          <li>
            <p>WE WILL ENDEAVOUR TO ENSURE THAT THE INFORMATION AVAILABLE ON OUR WEBSITE IS ACCURATE AND COMPLETE,
              HOWEVER TO THE EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE INCURRED BY YOU AS A
              RESULT OF THE INACCURACY OR INCOMPLETENESS OF THE INFORMATION.

              NO REPRESENTATIONS, WARRANTIES OR CONDITIONS ARE GIVEN BY US IN RESPECT OF ANY INFORMATION WHICH IS
              PROVIDED TO YOU ON OUR WEBSITE AND ANY SUCH REPRESENTATIONS, WARRANTIES OR CONDITIONS ARE EXCLUDED, SAVE
              TO THE EXTENT THAT SUCH EXCLUSION IS PROHIBITED BY LAW.

              TO THE EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE INCURRED BY YOU OR YOUR
              BUSINESS FIRM AS A RESULT OF:</p>
            <ul>
              <li>
                INCORRECT DETAILS (SUCH AS NAME, ID DETAILS, NATIONALITY, CONTACT DETAILS, CORPORATE NAME AND CORPORATE
                DOCUMENTS) SUBMITTED BY YOU OR YOUR BUSINESS FIRM. THIS INCLUDES, BUT IS NOT LIMITED TO, LOSS OR DAMAGE
                AS A RESULT OF NOT BEING ABLE TO CONTACT YOU OR YOUR BUSINESS FIRM WITH IMPORTANT INFORMATION ABOUT YOUR
                OR YOUR BUSINESS FIRM’S USE OF OUR WEBSITE, WHERE YOU OR YOUR BUSINESS FIRM HAS PROVIDED INCORRECT
                CONTACT DETAILS.
              </li>
              <li>
                THE RELEVANT SERVICES PROVIDER DETERMINING NOT TO OR CEASING TO PROVIDE YOU THE SERVICES FOR ANY REASON
                WHATSOEVER.
              </li>
              <li>
                ANY DIRECT COMMUNICATIONS BETWEEN YOU AND THE RELEVANT SERVICE PROVIDER.
              </li>
              <li>
                FALSE, INACCURATE, OUTDATED OR INCOMPLETE INFORMATION PROVIDED BY YOU AND/OR YOUR BUSINESS FIRM.
              </li>
              <li>
                <p>EVENTS OUTSIDE OF OUR CONTROL, INCLUDING BUT NOT LIMITED TO:</p>
                <ul>
                  <li>
                    ACTS OF GOD, FLOOD, DROUGHT, EARTHQUAKE OR OTHER NATURAL DISASTER;
                  </li>
                  <li>
                    EPIDEMIC OR PANDEMIC;
                  </li>
                  <li>
                    TERRORIST ATTACK, WAR, COMMOTION OR RIOTS, THREAT OF OR PREPARATION FOR WAR, ARMED CONFLICT,
                    IMPOSITION OF SANCTIONS, EMBARGO, OR BREAKING OFF OF DIPLOMATIC RELATIONS;
                  </li>
                  <li>
                    NUCLEAR, CHEMICAL OR BIOLOGICAL CONTAMINATION OR SONIC BOOM;
                  </li>
                  <li>
                    ANY LAW OR ANY ACTION TAKEN BY A GOVERNMENT OR PUBLIC AUTHORITY; AND
                  </li>
                  <li>
                    INTERNET, SYSTEM OR DEVICE INSTABILITY, COMPUTER VIRUSES AND HACKER ATTACKS.
                  </li>
                </ul>
              </li>

            </ul>
          </li>
          <li>
            WE HAVE NO LIABILITY TO YOU FOR ANY LOSS OF PROFIT, LOSS OF BUSINESS, BUSINESS INTERRUPTION, OR LOSS OF
            BUSINESS OPPORTUNITY.
          </li>
        </ul>
        <p>BREACH OF THESE TERMS BY YOU</p>
        <ul>
          <li>You and your Business Firm are responsible for any losses suffered by us as a result of any use by you
            and/or your Business Firm of our Website which is not in accordance with these terms.</li>
          <li>
            <p>Failure to comply with these terms may result in our taking all or any of the following actions:</p>
            <ul>
              <li>Immediate, temporary or permanent withdrawal of your and your Business Firm’s right to use our
                Website.</li>
              <li>Issue of a warning to you and your Business Firm.</li>
              <li> Legal proceedings against you and/or your Business Firm for reimbursement of all costs on an
                indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting
                from the breach.</li>
              <li>Further legal action against you and your Business Firm.</li>
              <li> Disclosure of such information to law enforcement authorities as we reasonably feel is necessary or
                as required by law. </li>
            </ul>
          </li>
        </ul>
        <h3>4).COMPLAINTS</h3>
        <p>If you have any questions or complaints about our Website, please contact us using the details in section 1
          of these terms.</p>
        <h3>5).GOVERNING LAW AND ARBITRATION</h3>
        <p>
          These terms are governed by the laws of the Republic of Singapore. Any dispute arising out of or in connection
          with these terms, including any question regarding their existence, validity or termination, shall be referred
          to and finally resolved by arbitration administered by the Singapore International Arbitration Centre (“SIAC”)
          in accordance with the Arbitration Rules of the Singapore International Arbitration Centre (“SIAC Rules”) for
          the time being in force, which rules are deemed to be incorporated by reference in this clause. The seat of
          the arbitration shall be Singapore. The Tribunal shall consist of three arbitrators. The language of the
          arbitration shall be English.
        </p>
        <h3>6).LANGUAGE</h3>
        <p>
          Where the original English version of these terms has been translated into other languages, the English
          version shall prevail in the event of any inconsistences between the versions.
        </p>
        <h3>
          7).GENERAL
        </h3>
        <ul>
          <li>
            These terms are between you, your Business Firm and us. No other person shall have any rights to enforce any
            of these terms.
          </li>
          <li>
            If any court or relevant authority finds that part of these terms are illegal, the rest will continue in
            full force and effect.
          </li>
          <li>
            Even if we delay enforcing these terms, we may still take steps against you and/or your Business Firm at a
            later date.
          </li>
        </ul>
      </div>
      <!-- <el-button plain @click="centerDialogVisible= false">关 闭</el-button> -->
    </el-dialog>
    <el-dialog :visible.sync="ysVisible" width="50%" center top="5vh">
      <div class="logo">
        <img src="../images/chip.png" alt="" />
        <div class="companyName">LI MAO INC</div>
      </div>
      <!-- <div class="content-title" v-if="this.$i18n.locale == 'hk'">隱私協議</div>
      <div class="content-title" v-if="this.$i18n.locale == 'cn'">隐私协议</div> -->
      <div class="content-title">
        Privacy Agreement
      </div>
      <!-- <div style="height: 60vh; overflow-y: auto" v-if="this.$i18n.locale == 'hk'">
        <h1>尊敬的用戶，歡迎閱讀本協議：</h1>
        <p>
          您的隱私對我們（「我們」或「我們的」）很重要。並且我們致力於保護您的隱私。因此，我們製定了隱私政策。本隱私政策（「隱私政策」）解釋了當您使用網站和服務時，我們如何處理您的信息並保護您的隱私，以及與您的信息有關的可用權利和選項。我們認為，您有權了解我們在使用網站和服務（「網站和服務」）時可能收集和使用的信息的做法。如果您不同意此處設定的條款和條件，請不要使用該網站和服務。
        </p>
        <h3>1.我們處理的信息以及處理您的信息的目的</h3>
        <p>
          我們將處理兩大類信息。我們的使命是不斷改進我們的網站和服務，並為您提供新的或更好的體驗。作為此任務的一部分，我們將您的信息用於以下目的。如果出現用於處理您的個人數據的任何新目的，我們將通過對本隱私政策進行相應的更改，在開始處理有關該新目的的信息之前通知您。
        </p>
        <ul>
          <li>
            <p>1.1您提交的信息</p>
            <ul>
              <li>
                (1)身份證和照片（不包括面部照片）。您可以自願授予我們訪問相機或相冊的權限，以便在您的移動設備中獲取照片。對於此類照片，我們既不會存儲也不會與任何第三方共享。此信息對於您與我們之間的合同的適當履行是必要的。
              </li>
              <li>
                (2)面部特征數據。首次使用面部識別功能時，您可以自願授予我們訪問相機和相冊的權限，以便在移動設備中獲取面部照片。只有您確認許可，我們才能訪問您的相機和相冊。然後，我們將使用第三方提供的sdk分析面部特征數據，識別照片中五個感官的位置和形狀，並處理照片，並將效果圖反饋給您。這樣的效果圖將不包含任何個人身份信息。對於此類數據，照片和效果圖，我們既不會存儲也不會與任何第三方共享。處理此類信息的目的僅在於操作應用程序的面部識別功能（包括但不限於衰老效果，性別變換，人臉融合等）。
              </li>
              <li>
                (3)處理此類信息的目的僅在於操作應用程序的人臉識別功能（包括但不限於衰老效果、性別轉化效果、人臉融合效果、換臉效果、濾鏡效果），相機和照片。為了使用該應用程序的某些功能，您可以自願授予我們訪問相機或相冊的權限，以便在您的移動設備中獲取照片。我們可能會通過使用Photo
                Kit
                API來收集您在使用該應用程序時上傳或提交的照片，圖片和其他數據。此信息對於您與我們之間的合同的適當履行是必要的。
              </li>
              <li>
                (4)支持相關信息。如果您通過我們的支持部分或通過電子郵件與我們聯系，我們可能會收集您的聯系信息，您的投訴或反饋以及您自願提供的與此支持有關的任何信息。此類信息僅用於為您提供支持服務，不會與通過網站和服務從您那裏收集的任何其他信息相關聯。
              </li>
              <li>
                <p>(5)您提交的信息的目的是：</p>
                <ul>
                  <li>1)操作和更新網站和服務；</li>
                  <li>2)改進和定製網站和服務及相關服務；</li>
                  <li>3)維護，測試和監視網站的質量和操作；</li>
                  <li>4)為您提供支持並處理您的投訴和/或反饋；</li>
                  <li>5)在涉及您與網站和服務有關的糾紛時采取任何措施；</li>
                  <li>
                    6)遵守並滿足任何適用的法律法規，法律程序或可執行的政府要求。
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <p>1.2自動處理的信息</p>
            <ul>
              <li>
                <p>(1)當您使用網站和服務時，我們將收集以下信息：</p>
                <ul>
                  <li>
                    -通用唯一標識符（UUID），移動設備的類型以及安裝在移動設備上的應用程序和操作系統版本；
                  </li>
                  <li>-國家，語言；</li>
                </ul>
              </li>
              <li>
                (2)元數據。元數據可以描述照片或其他文件中包含的位置信息和文件創建時間，或者照片中字符的粗略特征以及照片中包含的其他內容。我們使用照片元數據為您提供某些功能，包括精確的「面部識別」，「關鍵點識別」以及其他基於這些基本功能的自定義功能。
              </li>
              <li>
                <p>
                  (3)付款數據。我們可能收集有關您的訂購單的信息，例如購買商品，訂購時間，訂單狀態，付款時間。我們不收集，也不對收集付款細節或保證安全負責。付款信息是使用第三方公司處理和存儲的。在輸入您的個人付款詳細信息之前，我們建議您閱讀並熟悉這些第三方公司的隱私政策。當您開始使用網站和服務時，將通過自動數據處理技術自動處理信息。自動處理的信息的目的是：
                </p>
                <ul>
                  <li>
                    1)操作和更新網站和服務，並為您提供現有和新的功能和特性；
                  </li>
                  <li>2)改進和定製網站和服務及相關服務；</li>
                  <li>3)維護，測試，監視和改善網站的質量和操作；</li>
                  <li>
                    4)強製執行適用於網站和服務的條款和條件，並防止，檢測和調查欺詐，安全漏洞，潛在的禁止或非法活動及其濫用，保護我們的商標並執行我們的使用條款。
                  </li>
                </ul>
              </li>
              <li></li>
            </ul>
          </li>
          <li></li>
          <li></li>
        </ul>
        <h3>2.保護個人信息</h3>
        <ul>
          <li>
            我們采取預防措施，包括行政，技術和物理措施，以保護您的個人信息免遭丟失，盜竊和濫用以及未經授權的訪問，披露，更改和破壞。
          </li>
          <li>
            確保您的個人信息安全；我們會向所有員工傳達我們的隱私和安全準則，並嚴格執行公司內部的隱私保護措施。
          </li>
          <li>
            不幸的是，互聯網上的傳輸方法或電子存儲方法都不是100％安全的。我們盡力保護您的個人信息，但是，我們不能保證其絕對安全。如果您的個人信息因安全受到破壞而被盜用，我們將立即按照適用法律通知您。
          </li>
          <li>
            如果您對我們的網站和服務的安全性有任何疑問，可以通過下面顯示的電子郵件與我們聯系。
          </li>
        </ul>
        <h3>3.與第三方共享信息</h3>
        <p>除以下事件外，我們不會共享我們從您那裏收集的任何個人識別信息：</p>
        <ul>
          <li>
            3.1如果法律要求我們披露您的信息，我們可能會視需要與執法機構或其他主管當局和任何第三方共享您的信息（例如，檢測，預防或以其他方式解決欺詐，安全或技術問題）；回應要求或滿足任何法律程序，傳票或政府要求；或保護小組用戶，合作夥伴或公眾的權利，財產或人身安全）；
          </li>
          <li>
            3.2如果團隊經歷了業務過渡，例如另一家公司的合並或收購，合並，控製權變更，重組或出售其全部或部分資產，則您的信息將包含在轉讓的資產中。
          </li>
        </ul>
        <h3>4.第三方內容</h3>
        <p>
          某些網站和服務可能包括和/或使您能夠向其中添加內容。內容的形式可以是按鈕，小工具，指向第三方廣告，網站，產品和服務（包括搜索服務）的鏈接，以及第三方提供給您的其他方式（統稱為「第三方內容和服務」）。此類第三方內容和服務的使用受其隱私政策的約束。您應了解，使用第三方內容和服務可能使這些第三方能夠訪問，收集，存儲和/或共享您的信息，包括您的PII和非個人身份信息。為澄清起見，我們不對此類第三方內容和服務進行審查，批準，監控，認可，保證或作出任何陳述，並且您訪問任何第三方內容和服務的風險由您自行承擔。對於這些第三方內容和服務的實踐，您的使用或無法使用或無法使用這些信息，我們不承擔任何責任。您明確免除我們因使用此類第三方內容和服務而引起的任何責任。我們建議您在使用或訪問任何第三方內容和服務之前，請閱讀其條款，條件和隱私策。
        </p>
        <h3>5.將數據傳輸到您的區域之外</h3>
        <p>
          我們在國際空間工作，並向世界各地的用戶提供我們的網站和服務。我們和為網站和服務提供自動數據處理技術的第三方組織可能會將跨邊界的自動處理的信息從您所在的國家或地區轉移到世界其他國家或地區。根據法律要求，在此您位於當前位置的任何地方，在此明確表示同意您在使用網站和服務後將您的數據傳輸到當前位置以外的地方。您可以隨時通過以下官方網站電話與我們聯系，以撤回您的同意。
        </p>
        <h3>6.我們將如何保留您的個人數據</h3>
        <p>
          我們通常會保留您的個人信息，直到您與我們之間履行合同並遵守我們的法律義務。如果您不再希望我們使用我們實際訪問和存儲的信息，則可以要求我們擦除您的個人信息並關閉您的帳戶。特別的，在本產品中的面部數據，僅用於功能使用過程中的識別、提取、操作，不做任何存儲。但是，如果為了遵守法律義務（征稅，會計，審計）或為了維護安全和數據備份設置，防止欺詐或其他惡意行為而需要信息，某些數據可能仍會存儲一段時間。
        </p>
        <h3>7.您的數據權利</h3>
        <p>
          您有權訪問，修改，更正或刪除我們可能收集的任何個人數據。為了行使這項權利，請通過網站與我們聯系。如果您在歐洲經濟區，則您有權（除少數例外情況）：
        </p>
        <ul>
          <li>（i）請求訪問和更正或刪除您的個人信息；</li>
          <li>（ii）獲得處理限製或反對處理您的個人信息；</li>
          <li>
            （iii）要求以數字格式提供您的個人信息的副本。您也有權向EEA的本地數據保護機構投訴有關處理您的個人信息的投訴。
          </li>
        </ul>
        <h3>8.更改隱私政策</h3>
        <p>
          本隱私政策可能會不時更改。任何更改都將在網站界面上發布。您持續使用網站和服務將被視為您接受此類更新。
        </p>
        <h3>9.隱私問題</h3>
        <p>
          在本協議中未聲明的其他一切權利，仍歸本公司所有。本公司保留對本協議的最終解釋權利。如果您還有其他問題和建議，可以通過官方網站聯系我們。
        </p>
      </div>
      <div style="height: 60vh; overflow-y: auto" v-if="this.$i18n.locale == 'cn'">
        <h1>尊敬的用户，欢迎阅读本协议：</h1>
        <p>
          您的隐私对我们（“我们”或“我们的”）很重要。并且我们致力于保护您的隐私。因此，我们制定了隐私政策。本隐私政策（“隐私政策”）解释了当您使用网站和服务时，我们如何处理您的信息并保护您的隐私，以及与您的信息有关的可用权利和选项。我们认为，您有权了解我们在使用网站和服务（“网站和服务”）时可能收集和使用的信息的做法。如果您不同意此处设定的条款和条件，请不要使用该网站和服务。
        </p>
        <h3>1.我们处理的信息以及处理您的信息的目的</h3>
        <p>
          我们将处理两大类信息。我们的使命是不断改进我们的网站和服务，并为您提供新的或更好的体验。作为此任务的一部分，我们将您的信息用于以下目的。如果出现用于处理您的个人数据的任何新目的，我们将通过对本隐私政策进行相应的更改，在开始处理有关该新目的的信息之前通知您。
        </p>
        <ul>
          <li>
            <p>1.1您提交的信息</p>
            <ul>
              <li>
                (1)身份证和照片（不包括面部照片）。您可以自愿授予我们访问相机或相册的权限，以便在您的移动设备中获取照片。对于此类照片，我们既不会存储也不会与任何第三方共享。此信息对于您与我们之间的合同的适当履行是必要的。
              </li>
              <li>
                (2)面部特征数据。首次使用面部识别功能时，您可以自愿授予我们访问相机和相册的权限，以便在移动设备中获取面部照片。只有您确认许可，我们才能访问您的相机和相册。然后，我们将使用第三方提供的sdk分析面部特征数据，识别照片中五个感官的位置和形状，并处理照片，并将效果图反馈给您。这样的效果图将不包含任何个人身份信息。对于此类数据，照片和效果图，我们既不会存储也不会与任何第三方共享。处理此类信息的目的仅在于操作应用程序的面部识别功能（包括但不限于衰老效果，性别变换，人脸融合等）。
              </li>
              <li>
                (3)处理此类信息的目的仅在于操作应用程序的人脸识别功能（包括但不限于衰老效果、性别转化效果、人脸融合效果、换脸效果、滤镜效果），相机和照片。为了使用该应用程序的某些功能，您可以自愿授予我们访问相机或相册的权限，以便在您的移动设备中获取照片。我们可能会通过使用Photo
                Kit
                API来收集您在使用该应用程序时上传或提交的照片，图片和其他数据。此信息对于您与我们之间的合同的适当履行是必要的。
              </li>
              <li>
                (4)支持相关信息。如果您通过我们的支持部分或通过电子邮件与我们联系，我们可能会收集您的联系信息，您的投诉或反馈以及您自愿提供的与此支持有关的任何信息。此类信息仅用于为您提供支持服务，不会与通过网站和服务从您那里收集的任何其他信息相关联。
              </li>
              <li>
                <p>(5)您提交的信息的目的是：</p>
                <ul>
                  <li>1)操作和更新网站和服务；</li>
                  <li>2)改进和定制网站和服务及相关服务；</li>
                  <li>3)维护，测试和监视网站的质量和操作；</li>
                  <li>4)为您提供支持并处理您的投诉和/或反馈；</li>
                  <li>5)在涉及您与网站和服务有关的纠纷时采取任何措施；</li>
                  <li>
                    6)遵守并满足任何适用的法律法规，法律程序或可执行的政府要求。
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <p>1.2自动处理的信息</p>
            <ul>
              <li>
                <p>(1)当您使用网站和服务时，我们将收集以下信息：</p>
                <ul>
                  <li>
                    -通用唯一标识符（UUID），移动设备的类型以及安装在移动设备上的应用程序和操作系统版本；
                  </li>
                  <li>-国家，语言；</li>
                </ul>
              </li>
              <li>
                (2)元数据。元数据可以描述照片或其他文件中包含的位置信息和文件创建时间，或者照片中字符的粗略特征以及照片中包含的其他内容。我们使用照片元数据为您提供某些功能，包括精确的“面部识别”，“关键点识别”以及其他基于这些基本功能的自定义功能。
              </li>
              <li>
                <p>
                  (3)付款数据。我们可能收集有关您的订购单的信息，例如购买商品，订购时间，订单状态，付款时间。我们不收集，也不对收集付款细节或保证安全负责。付款信息是使用第三方公司处理和存储的。在输入您的个人付款详细信息之前，我们建议您阅读并熟悉这些第三方公司的隐私政策。当您开始使用网站和服务时，将通过自动数据处理技术自动处理信息。自动处理的信息的目的是：
                </p>
                <ul>
                  <li>
                    1)操作和更新网站和服务，并为您提供现有和新的功能和特性；
                  </li>
                  <li>2)改进和定制网站和服务及相关服务；</li>
                  <li>3)维护，测试，监视和改善网站的质量和操作；</li>
                  <li>
                    4)强制执行适用于网站和服务的条款和条件，并防止，检测和调查欺诈，安全漏洞，潜在的禁止或非法活动及其滥用，保护我们的商标并执行我们的使用条款。
                  </li>
                </ul>
              </li>
              <li></li>
            </ul>
          </li>
          <li></li>
          <li></li>
        </ul>
        <h3>2.保护个人信息</h3>
        <ul>
          <li>
            我们采取预防措施，包括行政，技术和物理措施，以保护您的个人信息免遭丢失，盗窃和滥用以及未经授权的访问，披露，更改和破坏。
          </li>
          <li>
            确保您的个人信息安全；我们会向所有员工传达我们的隐私和安全准则，并严格执行公司内部的隐私保护措施。
          </li>
          <li>
            不幸的是，互联网上的传输方法或电子存储方法都不是100％安全的。我们尽力保护您的个人信息，但是，我们不能保证其绝对安全。如果您的个人信息因安全受到破坏而被盗用，我们将立即按照适用法律通知您。
          </li>
          <li>
            如果您对我们的网站和服务的安全性有任何疑问，可以通过下面显示的电子邮件与我们联系。
          </li>
        </ul>
        <h3>3.与第三方共享信息</h3>
        <p>除以下事件外，我们不会共享我们从您那里收集的任何个人识别信息：</p>
        <ul>
          <li>
            3.1如果法律要求我们披露您的信息，我们可能会视需要与执法机构或其他主管当局和任何第三方共享您的信息（例如，检测，预防或以其他方式解决欺诈，安全或技术问题）；回应要求或满足任何法律程序，传票或政府要求；或保护小组用户，合作伙伴或公众的权利，财产或人身安全）；
          </li>
          <li>
            3.2如果团队经历了业务过渡，例如另一家公司的合并或收购，合并，控制权变更，重组或出售其全部或部分资产，则您的信息将包含在转让的资产中。
          </li>
        </ul>
        <h3>4.第三方内容</h3>
        <p>
          某些网站和服务可能包括和/或使您能够向其中添加内容。内容的形式可以是按钮，小工具，指向第三方广告，网站，产品和服务（包括搜索服务）的链接，以及第三方提供给您的其他方式（统称为“第三方内容和服务”）。此类第三方内容和服务的使用受其隐私政策的约束。您应了解，使用第三方内容和服务可能使这些第三方能够访问，收集，存储和/或共享您的信息，包括您的PII和非个人身份信息。为澄清起见，我们不对此类第三方内容和服务进行审查，批准，监控，认可，保证或作出任何陈述，并且您访问任何第三方内容和服务的风险由您自行承担。对于这些第三方内容和服务的实践，您的使用或无法使用或无法使用这些信息，我们不承担任何责任。您明确免除我们因使用此类第三方内容和服务而引起的任何责任。我们建议您在使用或访问任何第三方内容和服务之前，请阅读其条款，条件和隐私策。
        </p>
        <h3>5.将数据传输到您的区域之外</h3>
        <p>
          我们在国际空间工作，并向世界各地的用户提供我们的网站和服务。我们和为网站和服务提供自动数据处理技术的第三方组织可能会将跨边界的自动处理的信息从您所在的国家或地区转移到世界其他国家或地区。根据法律要求，在此您位于当前位置的任何地方，在此明确表示同意您在使用网站和服务后将您的数据传输到当前位置以外的地方。您可以随时通过以下官方网站电话与我们联系，以撤回您的同意。
        </p>
        <h3>6.我们将如何保留您的个人数据</h3>
        <p>
          我们通常会保留您的个人信息，直到您与我们之间履行合同并遵守我们的法律义务。如果您不再希望我们使用我们实际访问和存储的信息，则可以要求我们擦除您的个人信息并关闭您的帐户。特别的，在本产品中的面部数据，仅用于功能使用过程中的识别、提取、操作，不做任何存储。但是，如果为了遵守法律义务（征税，会计，审计）或为了维护安全和数据备份设置，防止欺诈或其他恶意行为而需要信息，某些数据可能仍会存储一段时间。
        </p>
        <h3>7.您的数据权利</h3>
        <p>
          您有权访问，修改，更正或删除我们可能收集的任何个人数据。为了行使这项权利，请通过网站与我们联系。如果您在欧洲经济区，则您有权（除少数例外情况）：
        </p>
        <ul>
          <li>（i）请求访问和更正或删除您的个人信息；</li>
          <li>（ii）获得处理限制或反对处理您的个人信息；</li>
          <li>
            （iii）要求以数字格式提供您的个人信息的副本。您也有权向EEA的本地数据保护机构投诉有关处理您的个人信息的投诉。
          </li>
        </ul>
        <h3>8.更改隐私政策</h3>
        <p>
          本隐私政策可能会不时更改。任何更改都将在网站界面上发布。您持续使用网站和服务将被视为您接受此类更新。
        </p>
        <h3>9.隐私问题</h3>
        <p>
          在本协议中未声明的其他一切权利，仍归本公司所有。本公司保留对本协议的最终解释权利。如果您还有其他问题和建议，可以通过官方网站联系我们。
        </p>
      </div> -->
      <div style="height: 60vh; overflow-y: auto">
        <h1>Dear user, welcome to read this agreement:</h1>
        <p>
          Your privacy is important to us ("us" or "ours"). And we are committed
          to protecting your privacy. Therefore, we have established a privacy
          policy. This Privacy Policy ("Privacy Policy") explains how we handle
          your information and protect your privacy when you use websites and
          services, as well as the available rights and options related to your
          information. We believe that you have the right to learn about the
          practices we may collect and use when using websites and services
          ("Websites and Services"). If you do not agree to the terms and
          conditions set here, please do not use this website and services.
        </p>
        <h3>
          1.The information we process and the purpose of processing your
          information
        </h3>
        <p>
          We will handle two types of information. Our mission is to
          continuously improve our website and services, and provide you with
          new or better experiences. As part of this task, we will use your
          information for the following purposes. If there are any new purposes
          for processing your personal data, we will notify you by making
          corresponding changes to this privacy policy before starting to
          process information related to that new purpose.
        </p>
        <ul>
          <li>
            <p>1.1The information you submitted</p>
            <ul>
              <li>
                (1)ID card and photos (excluding facial photos). You can
                voluntarily grant us access to the camera or album to obtain
                photos on your mobile device. For such photos, we will neither
                store nor share them with any third party. This information is
                necessary for the proper performance of the contract between you
                and us.
              </li>
              <li>
                (2)Facial feature data. When using facial recognition function
                for the first time, you can voluntarily grant us access to the
                camera and album to obtain facial photos on mobile devices. We
                can only access your camera and album if you confirm your
                permission. Then, we will use third-party provided SDK to
                analyze facial feature data, identify the positions and shapes
                of the five senses in the photo, process the photo, and provide
                feedback on the rendering to you. This rendering will not
                contain any personal identification information. For such data,
                photos, and renderings, we neither store nor share them with any
                third party. The purpose of processing such information is only
                to operate the facial recognition function of the application
                (including but not limited to aging effects, gender
                transformation, facial fusion, etc.).
              </li>
              <li>
                (3)The purpose of processing such information is only to operate
                the facial recognition function of the application (including
                but not limited to aging effects, gender conversion effects,
                facial fusion effects, face changing effects, filter effects),
                cameras, and photos. In order to use certain features of this
                application, you may voluntarily grant us access to the camera
                or album to obtain photos on your mobile device. We may use
                Photo Kit API to collect photos, images, and other data that you
                upload or submit while using the application. This information
                is necessary for the proper performance of the contract between
                you and us.
              </li>
              <li>
                (4)Supporting relevant information. If you contact us through
                our support section or via email, we may collect your contact
                information, your complaints or feedback, and any information
                you voluntarily provide related to this support. This type of
                information is only used to provide you with support services
                and will not be associated with any other information collected
                from you through the website and services.
              </li>
              <li>
                <p>(5)The purpose of the information you submitted is：</p>
                <ul>
                  <li>1)Operate and update websites and services；</li>
                  <li>
                    2)Improving and customizing websites, services, and related
                    services；
                  </li>
                  <li>
                    3)Maintain, test, and monitor the quality and operation of
                    the website；
                  </li>
                  <li>
                    4)To provide you with support and handle your complaints
                    and/or feedback；
                  </li>
                  <li>
                    5)Take any measures in disputes related to your website and
                    services；
                  </li>
                  <li>
                    6)Comply with and comply with any applicable laws,
                    regulations, legal procedures, or enforceable government
                    requirements。
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <p>1.2Automatically processed information</p>
            <ul>
              <li>
                <p>
                  (1)When you use websites and services, we will collect the
                  following information：
                </p>
                <ul>
                  <li>
                    -Universal Unique identifier (UUID), the type of mobile
                    device and the application and operating system version
                    installed on the mobile device；
                  </li>
                  <li>-Country, Language；</li>
                </ul>
              </li>
              <li>
                (2)Metadata. Metadata can describe the location information and
                file creation time contained in photos or other files, as well
                as the rough characteristics of characters in photos and other
                content contained in photos. We use photo metadata to provide
                you with certain features, including precise facial recognition,
                keypoint recognition, and other custom features based on these
                basic features.
              </li>
              <li>
                <p>
                  (3)Payment data. We may collect information about your
                  purchase order, such as purchasing goods, ordering time, order
                  status, and payment time. We do not collect, nor are we
                  responsible for collecting payment details or ensuring
                  security. Payment information is processed and stored by a
                  third-party company. Before entering your personal payment
                  details, we recommend that you read and familiarize yourself
                  with the privacy policies of these third-party companies. When
                  you start using websites and services, information will be
                  automatically processed through automated data processing
                  technology. The purpose of automatically processed information
                  is to:
                </p>
                <ul>
                  <li>
                    1)Operate and update websites and services, and provide you
                    with existing and new features and features;
                  </li>
                  <li>
                    2)Improving and customizing websites, services, and related
                    services；
                  </li>
                  <li>
                    3)Maintain, test, monitor, and improve the quality and
                    operation of the website；
                  </li>
                  <li>
                    4)Enforce the terms and conditions applicable to websites
                    and services, and prevent, detect, and investigate fraud,
                    security vulnerabilities, potential prohibited or illegal
                    activities and their abuse, protect our trademarks, and
                    enforce our terms of use.
                  </li>
                </ul>
              </li>
              <li></li>
            </ul>
          </li>
          <li></li>
          <li></li>
        </ul>
        <h3>2.Protecting personal information</h3>
        <ul>
          <li>
            We take preventive measures, including administrative, technical,
            and physical measures, to protect your personal information from
            loss, theft, abuse, and unauthorized access, disclosure, alteration,
            and destruction.
          </li>
          <li>
            Ensure the security of your personal information; We will
            communicate our privacy and security guidelines to all employees and
            strictly implement internal privacy protection measures within the
            company.
          </li>
          <li>
            Unfortunately, neither the transmission method nor the electronic
            storage method on the Internet is 100% secure. We do our best to
            protect your personal information, but we cannot guarantee its
            absolute security. If your personal information is stolen due to
            security breaches, we will immediately notify you in accordance with
            applicable laws.
          </li>
          <li>
            If you have any questions about the security of our website and
            services, you can contact us through the email shown below.
          </li>
        </ul>
        <h3>3.Sharing information with third parties</h3>
        <p>
          Except for the following events, we will not share any personally
          identifiable information we have collected from you：
        </p>
        <ul>
          <li>
            3.1 If the law requires us to disclose your information, we may
            share your information with law enforcement agencies or other
            competent authorities and any third party as necessary (such as
            detecting, preventing, or otherwise addressing fraud, security, or
            technical issues); Respond to requests or comply with any legal
            proceedings, subpoenas, or government demands; Or protect the
            rights, property, or personal safety of group users, partners, or
            the public;
          </li>
          <li>
            3.2If the team has undergone a business transition, such as a merger
            or acquisition of another company, merger, change of control,
            restructuring or sale of all or part of its assets, your information
            will be included in the transferred assets.
          </li>
        </ul>
        <h3>4.Third party content</h3>
        <p>
          Some websites and services may include and/or enable you to add
          content to them. The form of content can be buttons, widgets, links to
          third-party advertisements, websites, products and services (including
          search services), as well as other means provided to you by third
          parties (collectively referred to as "third-party content and
          services"). The use of such third-party content and services is
          subject to their privacy policies. You should be aware that using
          third-party content and services may enable these third parties to
          access, collect, store, and/or share your information, including your
          PII and non personally identifiable information. For the sake of
          clarification, we do not review, approve, monitor, acknowledge,
          guarantee or make any representations regarding such third-party
          content and services, and the risk of your accessing any third-party
          content and services is your own responsibility. We do not assume any
          responsibility for your use or inability to use or access these
          third-party content and services in practice. You expressly exempt us
          from any liability arising from the use of such third-party content
          and services. We suggest that you read the terms, conditions, and
          privacy policy of any third-party content and services before using or
          accessing them.
        </p>
        <h3>5.Transfer data outside of your area</h3>
        <p>
          We work in international space and provide our website and services to
          users around the world. We and third-party organizations that provide
          automated data processing technology for websites and services may
          transfer cross border automated information from your country or
          region to other countries or regions around the world. According to
          legal requirements, anywhere you are located in your current location,
          you hereby expressly agree to transfer your data to a location outside
          of your current location after using the website and services. You can
          contact us at any time by phone on the official website below to
          withdraw your consent.
        </p>
        <h3>6.How will we retain your personal data</h3>
        <p>
          We usually retain your personal information until you fulfill the
          contract with us and comply with our legal obligations. If you no
          longer wish us to use the information we actually access and store,
          you can request us to erase your personal information and close your
          account. Specifically, facial data in this product is only used for
          recognition, extraction, and operation during functional use, and is
          not stored in any way. However, if information is required to comply
          with legal obligations (taxation, accounting, auditing) or to maintain
          security and data backup settings, prevent fraud or other malicious
          behavior, certain data may still be stored for a period of time.
        </p>
        <h3>7.Your data rights</h3>
        <p>
          You have the right to access, modify, correct or delete any personal
          data that we may collect. To exercise this right, please contact us
          through the website. If you are in the European Economic Area, you
          have the right (with a few exceptions)：
        </p>
        <ul>
          <li>
            （i）Requesting access and correction or deletion of your personal
            information；
          </li>
          <li>
            （ii）Obtaining processing restrictions or opposing the processing
            of your personal information；
          </li>
          <li>
            （iii）Please provide a copy of your personal information in digital
            format. You also have the right to file a complaint with EEA's local
            data protection agency regarding the processing of your personal
            information。
          </li>
        </ul>
        <h3>8.Change Privacy Policy</h3>
        <p>
          This privacy policy may be subject to change from time to time. Any
          changes will be published on the website interface. Your continued use
          of the website and services will be considered as acceptance of such
          updates.
        </p>
        <h3>9.Privacy issues</h3>
        <p>
          All other rights not declared in this agreement shall remain the
          property of our company. Our company reserves the final interpretation
          right of this agreement. If you have any other questions or
          suggestions, you can contact us through the official website.
        </p>
      </div>
      <!-- <el-button plain @click="ysVisible= false">关 闭</el-button> -->
    </el-dialog>
    <el-dialog :visible.sync="tkVisible" width="50%" center top="5vh">
      <div class="logo">
        <img src="../images/chip.png" alt="" />
        <div class="companyName">LI MAO INC</div>
      </div>
      <!-- <div class="content-title" v-if="this.$i18n.locale == 'hk'">保密協議</div>
      <div class="content-title" v-if="this.$i18n.locale == 'cn'">保密协议</div> -->
      <div class="content-title">
        Confidentiality Agreement
      </div>
      <!-- <div v-if="this.$i18n.locale == 'hk'">
        <h3>退款條件：</h3>
        <ul>
          <li>1.正確使用平臺資金，無出現違規以及違法行為。</li>
          <li>2.出現金額到賬失敗的情況。</li>
        </ul>
        <h3>退款方式：</h3>
        <ul>
          <li>
            <p>1.退款期限和渠道</p>
            <p>
              您將在申請通過後的七(7)天內收到您消費的全額退款，您可以選擇通過您消費時使用的相同支付方式退款或由平臺錢包接受退款。如您使用個人其他賬戶余額進行消費，則與之相關的退款僅可退回至您的個人其他賬戶余額內。若平臺由於任何原因無法通過您消費時使用的支付方式進行退款，或您使用的支付方式不支持退款，則該退款將會退至您的平臺賬戶內。
            </p>
          </li>
          <li>
            <p>2.如何請求退款</p>
            <p>
              您可以在平臺申請退款頁面請求退款或獲得其他協助。我們不接受以其他任何方式發起的退款申請。
            </p>
          </li>
          <li>
            <p>3.濫用</p>
            <p>
              退款是為了降低您在平臺充值消費失敗的風險而設計，而非為了惡意運用本平臺退款機製進行獲利。如果在我們看來您正在濫用退款機製，我們可能不會通過您的退款申請。
            </p>
          </li>
        </ul>
      </div>
      <div v-if="this.$i18n.locale == 'cn'">
        <h3>退款条件：</h3>
        <ul>
          <li>1.正确使用平台资金，无出现违规以及违法行为。</li>
          <li>2.出现金额到账失败的情况。</li>
        </ul>
        <h3>退款方式：</h3>
        <ul>
          <li>
            <p>1.退款期限和渠道</p>
            <p>
              您将在申请通过后的七(7)天内收到您消费的全额退款，您可以选择通过您消费时使用的相同支付方式退款或由平台钱包接受退款。如您使用个人其他账户余额进行消费，则与之相关的退款仅可退回至您的个人其他账户余额内。若平台由于任何原因无法通过您消费时使用的支付方式进行退款，或您使用的支付方式不支持退款，则该退款将会退至您的平台账户内。
            </p>
          </li>
          <li>
            <p>2.如何请求退款</p>
            <p>
              您可以在平台申请退款页面请求退款或获得其他协助。我们不接受以其他任何方式发起的退款申请。
            </p>
          </li>
          <li>
            <p>3.滥用</p>
            <p>
              退款是为了降低您在平台充值消费失败的风险而设计，而非为了恶意运用本平台退款机制进行获利。如果在我们看来您正在滥用退款机制，我们可能不会通过您的退款申请。
            </p>
          </li>
        </ul>
      </div> -->
      <div>
        <h3>YOU AGREE AS FOLLOWS:</h3>
        <h4>1. Where Confidential Information is disclosed, you shall:</h4>
        <ul>
          <li>
            (a) not use or allow the use of the Confidential Information for any purpose other than the Purpose stated
            herein, and
          </li>
          <li>
            <p>
              (b) not disclose or allow disclosure to others except in connection with the Purpose and then only to
              either:
            </p>
            <ul>
              <li>
                (i) your directors, employees, officers, agents, advisors (including solicitors and accountants),
                consultants and other representatives (collectively Representatives) and those Representatives of any
                affiliated entities under the same control of the same entity as controls you, and/or
              </li>
              <li>
                (ii) such other third party as LI MAO INC shall agree in writing from time to time,
              </li>
              <li>
                (Collectively the Permitted Recipients)
              </li>
            </ul>
          </li>
        </ul>
        <h4>2. You shall protect all Confidential Information received using a reasonable degree of care which shall be
          at
          least the same degree of care as you normally exercise to protect your own confidential information of like
          character and importance and which shall include (without limitation):</h4>
        <ul>LI MAO INC
          <li>
            <p>
              (a) only providing Confidential Information to Representatives whose duties require them to have
              Confidential Information and who are under an obligation to maintain the confidentiality of such
              information;
            </p>
          </li>
          <li>
            <p>
              (b) imposing on any third parties to whom a disclosure has been made a written obligation to treat the
              Confidential Information as confidential according to the terms of this Agreement (unless such recipients
              are already under a similar duty of confidentiality);
            </p>
          </li>
          <li>
            <p>
              (c) properly storing all written Confidential Information in your possession or under its control; and
            </p>
          </li>
          <li>
            <p>
              (d) not making unnecessary copies of written or electronic Confidential Information. </p>
          </li>

        </ul>
        <h4>
          3. Without the prior written consent of LI MAO INC, you shall not disclose other than to Permitted Recipients,
          and
          shall direct your Permitted Recipients not to, disclose to any person any Confidential Information or the fact
          that discussions or negotiations are taking place concerning a possible transaction between you and LI MAO INC
          or any of the terms, conditions, or other facts with respect to any such possible transaction, including the
          status thereof, unless the disclosure is required to comply with the laws in force in any applicable
          jurisdiction (including without limitation the rules, regulations, policies and procedures established by any
          securities commission, stock exchange or other regulatory body having jurisdiction over you or a Permitted
          Recipient or legal process or the order or rules of any court or regulatory body of competent jurisdiction).
          If you or any other Permitted Recipient is required to make disclosure as set forth above, you may proceed
          with such disclosure provided that you have, if allowed by the applicable laws, used all reasonable efforts
          to:
        </h4>
        <ul>
          <li>
            <p>(i) provide LI MAO INC with advance notice of the nature and extent of such disclosure so that LI MAO INC
              may, at its own expense, seek a protective order or other appropriate remedy to avoid or limit such
              disclosure; and </p>
          </li>
          <li>
            <p>(ii) consult with LI MAO INC as to the content and timing of such disclosure.</p>
          </li>
        </ul>
        <h4>4. The obligations imposed by this Agreement shall not apply to any information that:</h4>
        <ul>
          <li>
            <p>(a) is lawfully known by you or is already in your possession at the time of disclosure, or</p>
          </li>
          <li>
            <p>(b) is lawfully received by you from a third party who does not have an obligation of confidentiality to
              LI MAO INC,</p>
          </li>
          <li>
            <p>In the case of paragraph (b) above, the removal of the restriction shall be effective only from and after
              the occurrence of the applicable event.</p>
          </li>
        </ul>
        <h4>5.You shall notify LI MAO INC immediately upon discovery of any unauthorised use or disclosure of any
          Confidential
          Information or any breach of this Agreement by you or any Permitted Recipient and shall provide such
          reasonable assistance as is necessary to help LI MAO INC regain possession of the Confidential Information and
          prevent its further unauthorised use.</h4>
        <h4>
          6.This Agreement does not, nor does the act of disclosure imply or confer upon you any right, license,
          interest or title to the Confidential Information provided by LI MAO INC. All Confidential Information
          provided
          by LI MAO INC under this Agreement together with all materials provided shall remain the property of LI MAO
          INC or
          its licensors. Upon the written request of LI MAO INC, you shall either collect and surrender, or confirm the
          destruction or non-recoverable data erasure of all Confidential Information and all copies thereof, regardless
          of form, including information based on or incorporating any Confidential Information and any such destruction
          shall be certified in writing to LI MAO INC by an authorised officer of you supervising such destruction.
        </h4>
        <h4>7.Neither the execution and delivery of this Agreement nor the disclosure of any Confidential Information
          hereunder shall be construed as granting by implication, estoppel or otherwise, any right in or license under
          any present or future invention, trade secret, trademark copyright, or patent now or hereafter owned or
          controlled by any of the Parties hereto.</h4>
        <h4>
          8.LI MAO INC is not obligated to furnish any Confidential Information and/or to provide any services to you.
        </h4>
        <h4>
          9.The opportunity to receive Confidential Information under this Agreement may be terminated at any time upon
          written notice by LI MAO INC to you. Such termination shall not affect any obligation imposed by this
          Agreement
          with respect to any Confidential Information received prior to such termination.
        </h4>
        <h4>
          10.You acknowledge that the unauthorised disclosure or use of the Confidential Information could cause
          irreparable damage to LI MAO INC and agree to compensate LI MAO INC for its losses, including but not limited
          to
          loss of goodwill or reputation, loss of profit, loss of income, loss of business interruption, loss of
          business opportunities, loss of anticipated profit and other indirect or consequential losses, as well as
          administrative penalties, fines, legal fees, notarial fees, etc.. Without prejudice to any other rights or
          remedies which LI MAO INC may have, you acknowledge and agree that damages may not be an adequate remedy for
          any
          breach by you of the provisions of this Agreement and LI MAO INC shall be entitled to the remedies of
          injunction, specific performance and other equitable relief for any threatened or actual breach of any such
          provision by you and no proof of special damages shall be necessary for the enforcement by LI MAO INC of its
          rights under this Agreement. You shall notify LI MAO INC immediately upon becoming aware of any such breach or
          threatened breach. LI MAO INC shall be entitled to recover from you in such litigation all of its costs,
          expenses and reasonable attorney fees paid or incurred in connection therewith.
        </h4>
        <h4>11.LI MAO INC may amend this Agreement from time to time by giving prior notice of a period of not less than
          10 days. LI MAO INC will note the date that amendments take effect at the top of this Agreement. LI MAO INC
          may
          give notice through its website or by other means as it considers appropriate.
          If
          you do not accept any amendment, you should stop using any of the LI MAO INC Services before the effective
          date
          of the amendment. If you continue to use any LI MAO INC Services after the effective date, you are deemed to
          have accepted and shall be bound by such amendment.</h4>
        <h4>12.This Agreement (or any discussion or disclosures under or in connection with this Agreement) shall not be
          deemed as a commitment to any business relationship, contract or future dealing between you and LI MAO INC.
        </h4>
        <h4>13.This Agreement (or any discussion or disclosures under or in connection with this Agreement) shall not
          prevent LI MAO INC from conducting similar discussion with, or providing any similar services to, any other
          parties.</h4>
        <h4>14.This Agreement shall be governed by, and construed in accordance with, the laws of Hong Kong. Any dispute
          arising from or in connection with this Agreement shall be submitted to Shanghai International Economic and
          Trade Arbitration Commission for arbitration.</h4>
        <h4>15.This Agreement is between you and LI MAO INC. Unless other provided under this Agreement, no other person
          shall have any rights to enforce any of the terms of this Agreement. If any court or relevant authority finds
          that part of the terms of this Agreement are illegal, the rest will continue in full force and effect. Even if
          LI MAO INC delays enforcing this Agreement, it may still take steps against you and/or your Permitted
          Recipients
          at a later date.</h4>
      </div>
      <!-- <el-button plain @click="tkVisible= false">关 闭</el-button> -->
    </el-dialog>
  </div>
</template>

<script>
import ctdata from "../plugins/countryData.json";

export default {
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("register.des8")));
      } else if (value !== this.form_reg.password) {
        callback(new Error(this.$t("register.des8")));
      } else {
        callback();
      }
    };
    return {
      gjcodes: "+86 中国",
      gjda: ctdata,
      centerDialogVisible: false,
      ysVisible: false,
      tkVisible: false,
      codeSrc: "",
      agr: false,
      Rpassword: "",
      emailType: '3', // 邮箱类型
      form_reg: {
        phonenumber: "",
        code: "",
        uuid: "",
        password: "",
        inviteCode: "yzqklZ9y",
        realcard: "",
        realname: "",
      }, // 表单验证规则
      regFormRules: {
        // 验证用户名
        phonenumber: [
          {
            required: true,
            message: this.$t("register.userrule"),
            tigger: "blur",
          },
          {
            min: 11,
            max: 11,
            message: this.$t("register.userLengthrule"),
            trigger: "blur",
          },
        ],
        // 验证密码
        password: [
          {
            required: true,
            message: this.$t("register.pswrule"),
            tigger: "blur",
          },
          {
            min: 5,
            max: 15,
            message: this.$t("register.pswLengthrule"),
            trigger: "blur",
          },
          {
            pattern: "^[0-9a-zA-Z_]{1,}$",
            message: this.$t("register.pswabcrule"),
          },
        ],
        Rpassword: [
          {
            required: true,
            message: this.$t("register.pswAgainrule"),
            tigger: "blur",
          },
          {
            min: 5,
            max: 15,
            message: this.$t("register.pswLengthrule"),
            trigger: "blur",
          },
          {
            validator: validatePass2,
            trigger: "blur",
          },
          {
            pattern: "^[0-9a-zA-Z_]{1,}$",
            message: this.$t("register.pswabcrule"),
          },
        ],
        inviteCode: [
          {
            required: true,
            message: this.$t("register.codeurule"),
            tigger: "blur",
          },
        ],
        email: [
          { required: true, message: this.$t("register.des9"), trigger: 'blur' },
          { type: 'email', message: this.$t("register.des9"), trigger: ['blur', 'change'] }
        ],
        realname: [
          {
            required: true,
            message: this.$t("register.namerule"),
            tigger: "blur",
          },
          {
            validator: this.validateChinese, // 使用自定义验证函数
            trigger: 'blur'
          }
        ],
        realcard: [
          {
            required: true,
            message: this.$t("register.idrule"),
            tigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    loadCSS(value) {
      this.removeCSS(value)
      var cssLink = document.createElement("link");
      cssLink.rel = "stylesheet";
      cssLink.type = "text/css";
      cssLink.href = `/register/theme/${value}/index.css`;
      cssLink.id = `theme-css-${value}`; // 设置唯一 ID
      document.getElementsByTagName("head")[0].appendChild(cssLink);
    },
    removeCSS(themeName) {
      var link = document.getElementById(`theme-css-${themeName}`);
      if (link) {
        link.parentNode.removeChild(link);
      }
    },
    validateChinese(rule, value, callback) {
      const chineseRegex = /^[\u4e00-\u9fa5]+$/;
      if (!value) {
        callback(new Error(this.$t("register.namerule"))); // 提示用户输入不能为空
      } else if (!chineseRegex.test(value)) {
        callback(new Error(this.$t("register.chineserule"))); // 提示用户输入必须是中文
      } else {
        callback(); // 验证通过
      }
    },
    register() {
      if (this.agr == false) {
        this.$message({
          message: "请选中用户协议!",
          type: "warning",
        });
        return;
      }
      let regs = {
        users: this.form_reg.phonenumber,
        email: this.form_reg.email,
        pass: this.form_reg.password,
        rpass: this.form_reg.Rpassword,
        invitation: this.form_reg.inviteCode,
        code: this.form_reg.code.toLowerCase(),
        codeid: this.uuid,
        realcard: this.form_reg.realcard,
        realname: this.form_reg.realname,
      };
      regs = this.$lmjm(regs);
      this.$refs.form_regFormRef.validate((valid) => {
        if (!valid) return this.$message.error("注册失败，请重新注册!");
        const result = this.$http.post("/api/v1/register", regs);
        result.then(
          (res) => {
            if (res.data.code === 200) {
              // 获得成功响应返回的数据
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.$cookie.set("user", this.form_reg.phonenumber);
              this.$router.push("/login");
            } else {
              this.$message.error(res.data.msg);
              this.getCodeUrl();
            }
          },
          (error) => {
            this.$message.error("注册失败，请重新注册!");
            this.getCodeUrl();
          }
        );
      });
    },
    go_login() {
      this.$router.push("/login");
    },
    getCodeUrl() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.get(
        "/api/v1/captcha?" +
        "key=" +
        params.key +
        "&noncestr=" +
        params.noncestr +
        "&sign=" +
        params.sign +
        "&timestmap=" +
        params.timestmap
      );
      result.then(
        (res) => {
          if (res.status === 200) {
            // 获得成功响应返回的数据
            this.codeSrc = res.data.data.codeurl;
            this.uuid = res.data.data.codeid;
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    getct() {
      const result = this.$http.get("../../plugins/countryData");
      result.then(
        (res) => {
          console.log(res);
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 判断ip
    getChinaIp() {
      // 判断用户IP
      let params = {};
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/getipaddress', params, {
        headers: {
        }
      })
      result.then((res) => {
        if (res.data.data.in_china == 1 && res.data.data.iplimit == 1) {
          window.open('/error.html', '_self')
          // router.push('/login')
        } else {
          this.$router.push("/register");
        }
      }, (error) => {
        console.log('错误:' + error)
      })
    },
  },
  mounted() {
    localStorage.setItem('theme', 'light')
    document.documentElement.setAttribute('theme', 'light')
    this.loadCSS('light')
  },
  created() {
    // if(this.form_reg.inviteCode !="yzqklZ9y"){
    //   const geturl = window.location.href
    //   const getqyinfo = geturl.split('?')[1]
    //   const getqys = new URLSearchParams('?' + getqyinfo)
    //   const getqycode = getqys.get('codes')
    //   this.form_reg.inviteCode = getqycode
    // }
    this.getChinaIp()
    const geturl = window.location.href;
    const getqyinfo = geturl.split("?")[1];
    const getqys = new URLSearchParams("?" + getqyinfo);
    const getqycode = getqys.get("codes");
    if (getqycode != null) {
      this.form_reg.inviteCode = getqycode;
    }

    this.getCodeUrl();
  },
};
</script>

<style>
#gjcodes input {
  height: 55px !important;
  background: transparent;
}
</style>
<style lang="less" scoped>
.logo {
  display: flex;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
  }

  .companyName {
    font-size: 26px;
    font-weight: 900;
    color: #000;
    font-style: italic;
  }
}

.content-title {
  padding: 20px 20px 5px;
  height: 54px;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
}

.reg_container {
  height: 100%;
  width: 100%;
  background-image: url(../images/04_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position-x: 100%;
}

.el-col,
.el-row {
  height: 100%;
}

.reg_box {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation: moveUpDown 2s;
  width: 620px;
  height: 725px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 24px;
  border: 1px solid rgba(85, 170, 238, 0.8);
  position: absolute;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 70px;
}

@keyframes moveUpDown {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }

  100% {
    width: 600px;
    height: 725px;
    opacity: 1;
  }
}

@keyframes mos {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.reg_title {
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 3% 0;
  font-size: 1.5em;
  box-sizing: border-box;
  animation: mos 2s;
}

button {
  width: 100%;
  height: 50px;
  font-size: 20px;
}

form {
  margin: 0% 5%;
  margin-top: 5%;
}

.reg_left {
  position: absolute;
  right: 5%;
  animation: mos 3s;
  margin-top: 20px;
}

.pzh {
  border-radius: 13px;
  position: relative;
  top: 3px;
}

.reg_right {
  position: absolute;
  left: 5%;
  margin-top: 20px;
  animation: mos 3s;
}

.codeInput .el-input--prefix {
  width: 70%;
}

.codeImg {
  width: 40%;
  height: 50px;
  float: right;
  border-radius: 5px;
  border: 2px solid #eee;
  position: relative;
  top: 10px;
}

.el-form,
.el-divider,
.btn_xx {
  animation: mos 2s;
}

.mysearch /deep/ .el-input__inner {
  height: 55px !important;
  font-size: 15px !important;
  border-radius: 10px !important;
  background: transparent;
}

.emailinput /deep/ .el-input__inner {
  height: 55px !important;
  font-size: 15px !important;
  border-radius: 4px !important;
  background: transparent;
}

.emailinput /deep/ .el-input-group__append {
  background-color: #fff;
}

.emailinput /deep/ .el-select {
  width: 150px;
}

.imgContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.bg_dw {
  position: absolute;
  left: 21%;
  top: 31%;
  opacity: 0;
  animation: zoomIn 800ms ease;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.bg_eg1 {
  position: absolute;
  left: 34%;
  top: 20%;
  opacity: 0;
  animation: zoomIn 800ms ease;
  animation-fill-mode: forwards;
  animation-delay: 1.4s;
}

.bg_eg3 {
  position: absolute;
  left: 50%;
  top: 48%;
  opacity: 0;
  animation: zoomIn 800ms ease;
  animation-fill-mode: forwards;
  animation-delay: 2.0s;
}

.bg_eg2 {
  position: absolute;
  left: 26%;
  top: 57%;
  opacity: 0;
  animation: zoomIn 800ms ease;
  animation-fill-mode: forwards;
  animation-delay: 1.8s;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  100% {
    opacity: 1;
  }
}

/deep/ .el-input .el-input__count .el-input__count-inner {
  background: transparent;
}
</style>
