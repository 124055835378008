import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/Login.vue'
import Forbidden from '../components/forbidden.vue'
import Forgot from '../components/forgot.vue'
import Home from '../components/home.vue'
import Developing from '../components/advertisement/index.vue' // 正在开发中页面模板
import Enterprise from '../components/advertisement/enterprise.vue'
import Business from '../components/advertisement/business.vue'
import Reg from '../components/register.vue'
import Welcome from '../components/welcome.vue'
import adminHome from '../components/admin/home.vue'
import rchageManage from '../components/admin/rchageSet.vue'
import cardManage from '../components/admin/cardManage.vue'
import noticeManage from '../components/admin/noticeManage.vue'
import ExamineManage from '../components/admin/examineManage.vue'
import AdvertisingReview from '../components/admin/AdvertisingReview.vue'
import dishonourManage from '../components/admin/dishonourManage.vue'
import bankRecharge from '../components/recharge/bankRecharge.vue'
import usermanage from '../components/admin/userManage.vue'
// import adminWelcome from '../components/admin/adminWelcome.vue'
import onlineRecharge from '../components/recharge/onlineRecharge.vue'
import rechargeRecord from '../components/recharge/rechargeRecord.vue'
import balanceDetails from '../components/balanceDetails/balanceDetails.vue'
import wallet from '../components/recharge/wallet.vue'
import applyCards from '../components/cardManage/applyCards.vue'
import billAddress from '../components/cardManage/billAddress.vue'
import queryCard from '../components/cardManage/queryCard.vue'
import carTrade from '../components/cardManage/carTrade.vue'
import customerManage from '../components/customerManage/customerManage.vue'
import MyFenData from '../components/customerManage/MyFenData.vue'
import ManUsdR from '../components/admin/ManUsdR.vue'
import RiskConfig from '../components/admin/RiskConfig.vue'
import AbnormalData from '../components/admin/AbnormalData.vue'
import Allwalldata from '../components/admin/Allwalldata.vue'
import Logs from '../components/admin/Logs.vue'
import comData from '../components/admin/comData.vue'
import monthData from '../components/admin/monthData.vue'
import withdrawal from '../components/withdrawal.vue'
import gg from '../components/gg.vue'
import ShopRecord from '../components/admin/ShopRecord.vue'
import getDivideInfoData from '../components/admin/getDivideInfoData.vue'
import getAllUserWiinfo from '../components/admin/getAllUserWiinfo.vue'
import axios from 'axios'
import Cookies from 'js-cookie'
import {
  MessageBox
} from 'element-ui'
import forge from '../plugins/forge.min.js'

//获取随机nonce
function createNonceStr() {
  return Math.random().toString(36).substr(2, 16);
}


// md5加密方法
function md5encrypt(val) {
  let md = forge.md.md5.create();
  md.update(val);
  let undateVal = md.digest().toHex();
  return undateVal;
}


// AES加密
// aes_key  加密的key
// val  加密的字符串内容
function aesencrypt(aes_key, val) {
  var cipher = forge.cipher.createCipher('AES-CBC', forge.util.decode64(aes_key));
  cipher.start({
    iv: forge.util.decode64(aes_key)
  });
  cipher.update(forge.util.createBuffer(val));
  cipher.finish();
  // outputs encrypted hex
  let aes_secret = forge.util.encode64(cipher.output.getBytes());
  return aes_secret;
}

// RSA加密方法
function encryptRsa(encrypt_key, clearText) {
  //注意此处上下的BEGIN PRIVATE KEY不要删除，框架自带的
  const public_key = '-----BEGIN PUBLIC KEY-----\n' +
    encrypt_key +
    '\n-----END PUBLIC KEY-----';
  var publicKey = forge.pki.publicKeyFromPem(public_key);
  var buffer = forge.util.createBuffer(clearText, 'utf8');
  var bytes = buffer.getBytes();
  var encryptedText = forge.util.encode64(publicKey.encrypt(bytes, 'RSAES-PKCS1-V1_5', {
    md: forge.md.sha256.create(),
    mgf1: {
      md: forge.md.sha1.create()
    }
  }));
  return encryptedText;
};



// 参数构造方法
// aes_key  aes加密的key，这个是动态的，由后端来决定，对接的时候写死，上线的时候向后端请求获取，并且每天凌晨变化一次
// jsondata {} 对象，向后端传递的所有参数param,不放进来token
// RSA加密方法，加密后返回原对象数据并新增了timestmap和sign属性
function gouzaoparame(aes_key, jsondata) {

  // RSA公钥放到全局配置文件当中
  let key =
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlYi/TUHKoJpgoHIShu7R4FIRYsJxCjHZ9Au+ITrEjES1Hmf+vp+8J2/ku8whL0vHW45V6Yayu9oBtR2zNbisrmeyDaRh2qYpwyUt4Vxvz65r2aiakxtNj8mYgmfxd5qsN/jOTmpKsyuYnBqPySbLW21sb9jH9wKSMW/WIUO5M9+GpsqtUFrfTJrj1g9hTRINNwqsIcqJdgD3j9RA88Ove27S1QoIGtlwi5Ofazze96rviXOAeoZ95PoWwkBpDb4auscQv++esEZuI9+XIowleQu7T+xxAfwIyWMB+AtCQExYpTGGZV1gDq/yMp81br+0I0v0EjOva5X08DVWRkDlcQIDAQAB';

  let ttt = Math.ceil(new Date().getTime() / 1000);
  jsondata.timestmap = ttt;
  jsondata.noncestr = aesencrypt(aes_key, createNonceStr())
  jsondata.key = aesencrypt(aes_key, "limaozhandian")
  let sortData = "";
  let indd = 0;
  Object.keys(jsondata).sort().map(key => {
    sortData += key + "=" + jsondata[key]
    if (Object.keys(jsondata).length - 1 != indd) {
      sortData += "&";
    }
    indd++;
  })
  let sign = encryptRsa(key, md5encrypt(window.btoa(unescape(encodeURIComponent(sortData)))));
  jsondata.sign = sign;
  return jsondata;
}

// aes_key
let aes_key = "gFy5IV0g55QlfS28VbvQ6A==";

// 添加以下代码
Vue.prototype.$isAdmin = false
Vue.prototype.$isCommon = false
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

const router = new Router({
  routes: [{
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/errorpage',
    component: Forbidden
  },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [{
      path: '/welcome',
      component: Welcome
    }, {
      path: '/bankRecharge',
      component: bankRecharge
    }, {
      path: '/onlineRecharge',
      component: onlineRecharge
    }, {
      path: '/rechargeRecord',
      component: rechargeRecord
    }, {
      path: '/wallet',
      component: wallet
    }, {
      path: '/billAddress',
      component: billAddress
    }, {
      path: '/applyCards',
      component: applyCards
    }, {
      path: '/queryCard',
      component: queryCard
    }, {
      path: '/carTrade',
      component: carTrade
    }, {
      path: '/customerManage',
      component: customerManage
    }, {
      path: '/MyFenData',
      component: MyFenData
    }, {
      path: '/balanceDetails',
      component: balanceDetails
    }, {
      path: '/dishonourManage',
      component: dishonourManage
    }, {
      path: '/withdrawal',
      component: withdrawal
    }, {
      path: '/advertising',
      component: gg
    }, {
      path: '/business',
      component: Business
    }, {
      path: '/enterprise',
      component: Enterprise
    },{
      path: '/shopping',
      component: Developing
    }]
  },
  {
    path: '/register',
    component: Reg
  },
  {
    path: '/forgotPW',
    component: Forgot
  },
  {
    path: '/admin/home',
    component: adminHome,
    redirect: '/admin/userManage',
    children: [{
      path: '/admin/userManage',
      component: usermanage
    }, {
      path: '/admin/rchageManage',
      component: rchageManage
    }, {
      path: '/admin/cardManage',
      component: cardManage
    }, {
      path: '/admin/noticeManage',
      component: noticeManage
    }, {
      path: '/admin/examineManage',
      component: ExamineManage
    },
    {
      path: '/admin/ManUsdR',
      component: ManUsdR
    },{
      path: '/admin/AbnormalData',
      component: AbnormalData
    },{
      path: '/admin/RiskConfig',
      component: RiskConfig
    }, {
      path: '/admin/Allwalldata',
      component: Allwalldata
    }, {
      path: '/admin/ShopRecord',
      component: ShopRecord
    }, {
      path: '/admin/ShopRecord',
      component: ShopRecord
    }, {
      path: '/admin/getAllUserWiinfo',
      component: getAllUserWiinfo
    }, {
      path: '/admin/AdvertisingReview',
      component: AdvertisingReview
    }, {
      path: '/admin/getDivideInfoData',
      component: getDivideInfoData
    }, {
      path: '/admin/Logs',
      component: Logs
    }, {
      path: '/admin/comData',
      component: comData
    }, {
      path: '/admin/monthData',
      component: monthData
    }]
  }, {
    path: '/admin',
    component: adminHome,
    redirect: '/admin/userManage'
  }
  ]
})

// 挂载路由守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转过来
  // next 是一个函数，表示放行
  if (to.path === '/login' || to.path === '/register' || to.path === '/forgotPW' || to.path === '/errorpage') return next()
  if (to.path === '/admin/userManage' || to.path === '/admin/ManUsdR' || to.path === '/admin/noticeManage' || to
    .path === ' /admin/noticeManage' || to.path === ' /admin/cardManage' || to.path === ' /admin/cardManage' || to
      .path === '/admin/rchageManage' || to.path === ' /admin/userManage' || to.path === ' /admin/userManage' || to
        .path === '/admin/home') {
    // 获取本地token
    let params = {};
    params = gouzaoparame(aes_key, params)
    const tokenStr = window.sessionStorage.getItem('token')
    var result = axios.post('/api/v1/getAuth', params, {
      headers: {
        token: tokenStr
      }
    })
    result.then((res) => {
      if (res.data.code === 200) {
        if (res.data.data.types != '3') {
          next('/welcome')
        }
      } else {
        next('/welcome')
      }
    }, (error) => {
      console.log(error)
    })
  } else if (to.path === '/onlineRecharge') {
    // 获取本地token
    let params = {};
    params = gouzaoparame(aes_key, params)
    const tokenStr = window.sessionStorage.getItem('token')
    var result = axios.post('/api/v1/getAuth', params, {
      headers: {
        token: tokenStr
      }
    })
    result.then((res) => {
      if (res.data.code === 200) {
        if (res.data.data.isreal == '1') {
          next('/welcome')
        } else {
          next()
        }
      } else {
        next('/welcome')
      }
    }, (error) => {
      console.log(error)
    })
  }
  // 获取本地token
  const tokenStr = window.sessionStorage.getItem('token')
  if (tokenStr == '' || tokenStr == null) {
    console.log('未登录')
      // 判断用户IP
      let params = {};
      const result = axios.post('/api/v1/getipaddress', params, {
        headers: {
        }
      })
      result.then((res) => {
        if (res.data.data.in_china == 1 && res.data.data.iplimit == 1) {
          window.open('/error.html', '_self')
          // next('/login')
        }else{
          next('/login')
        }
      }, (error) => {
        console.log('错误:' + error)
      })
  }else{
    let params = {};
    params = gouzaoparame(aes_key, params)
    var result = axios.post('/api/v1/getAuth', params, {
      headers: {
        token: tokenStr
      }
    })
    result.then((res) => {
      if (res.data.code === 200) {
        kfjg()
        if (res.data.data.types === 1) {
          next()
        } else if (res.data.data.types === 3) {
          Vue.prototype.$isAdmin = true
          Vue.prototype.$isCommon = true
          next()
        } else if (res.data.data.types === 2) {
          Vue.prototype.$isCommon = true
          next()
        }
      } else {
        next('/login')
      }
    }, (error) => {
      next('/login')
    })
  }


  function kfjg() {
    // 获取本地token
    const tokenStr = window.sessionStorage.getItem('token')
    if (tokenStr == '' || tokenStr == null) {
      console.log('未登录')
     // 判断用户IP
     let params = {};
     const result = axios.post('/api/v1/getipaddress', params, {
       headers: {
       }
     })
     result.then((res) => {
       if (res.data.data.in_china == 1 && res.data.data.iplimit == 1) {
         window.open('/error.html', '_self')
        //  next('/login')
       }else{
         next('/login')
       }
     }, (error) => {
       console.log('错误:' + error)
     })
    }
    let params = {};
    params = gouzaoparame(aes_key, params)
    var result = axios.post('api/v1/getuserinfos', params, {
      headers: {
        token: tokenStr
      }
    })
    result.then((res) => {
      if (res.data.code === 200) {
        Vue.prototype.$relv = res.data.data.relv
        Vue.prototype.$remoney = res.data.data.remoney
        Vue.prototype.$usd_disabled_mon = res.data.data.usd_disabled_mon
        if (res.data.data.usd < 0) {
          MessageBox.alert('请缴纳您美金钱包内的负余额。', '系统提示', {
            confirmButtonText: '确定',
            showClose: false,
            callback: action => {
              next('/onlineRecharge')
            }
          })
        }
      } else {
        next('/login')
      }
    }, (error) => {
      console.log(error)
    })
  }
})

export default router
