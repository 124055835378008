import { render, staticRenderFns } from "./ManUsdR.vue?vue&type=template&id=bca83c22&scoped=true"
import script from "./ManUsdR.vue?vue&type=script&lang=js"
export * from "./ManUsdR.vue?vue&type=script&lang=js"
import style0 from "./ManUsdR.vue?vue&type=style&index=0&id=bca83c22&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bca83c22",
  null
  
)

export default component.exports