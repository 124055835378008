<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('queryCard.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('queryCard.cardManagement') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('queryCard.title') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <!-- 查询卡片表单 -->
            <el-form :model="form_queryCards" :rules="form_queryCardsRules" ref="form_queryCardsRef">
              <!-- 卡号 -->
              <el-input v-model="form_queryCards.cardNo" prefix-icon="el-icon-bank-card"
                :placeholder="$t('queryCard.des1')">
              </el-input>
              <!-- 开卡日期查询 -->
              <el-date-picker v-model="selectTimes" type="date" :placeholder="$t('queryCard.creationTime')"
                format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
              </el-date-picker>
              <!-- 卡片状态 -->
              <el-select v-model="cardsTypesVal" :placeholder="$t('queryCard.cardStatus')" clearable>
                <el-option v-for="item in cardsTypes" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-button type="primary" v-no-more-click icon="el-icon-search" @click="getCardList()">{{
                $t('queryCard.search') }}</el-button>
              <el-button type="primary" v-no-more-click icon="el-icon-refresh" @click="ref()">{{ $t('queryCard.flushed')
                }}</el-button>
              <el-button type="primary" v-no-more-click icon="el-icon-coin" @click="plcz()">{{
                $t('queryCard.bulkRecharge') }}</el-button>
              <el-button type="primary" v-no-more-click style="margin-bottom: 20px" icon="el-icon-download"
                @click="downdocument()">
                {{ $t('queryCard.downloadTheCard') }}
              </el-button>
              <!-- <el-button type="primary" style="margin-bottom: 20px;" icon="el-icon-download">
                  下载 </el-button> -->
              <!-- <el-button type="primary" icon="el-icon-refresh" @click="refJe()">一键同步金额</el-button> -->

              <el-tag effect="dark" style="
                  float: right;
                  margin-bottom: 20px;
                  font-size: 0.8em;
                  top: 0;
                ">
                <span class="el-icon-bank-card"></span>
                {{ $t('queryCard.normalCard') }}: {{ this.cardListTotal }}
              </el-tag>
              <el-tag effect="dark" style="
                  float: right;
                  margin-right: 20px;
                  margin-bottom: 20px;
                  font-size: 0.8em;
                  top: 0;
                ">
                <span class="el-icon-download"></span> {{ $t('queryCard.numberOfDownloads') }}:
                {{ this.downum }}
              </el-tag>
              <!-- <el-tag effect="dark" style="float: right; margin-bottom: 20px; font-size: 0.8em;margin-right: 20px;">
                <span class="el-icon-loading"></span>
                余额: {{ usd }} USD
              </el-tag> -->
            </el-form>
            <!-- 表格 -->
            <el-table @selection-change="handleSelectionChange" :data="tableData" style="width: 100%; margin-top: 20px"
              border height="670" max-height="700" size="small" :default-sort="{ prop: 'date', order: 'descending' }"
              highlight-current-row>
              <el-table-column fixed="left" :label="$t('queryCard.operation')" width="100">
                <template slot-scope="scope">
                  <el-button @click="cardsDetails(scope.row)" type="text" size="small">{{
                    $t('queryCard.cardInformation') }}</el-button>
                </template>
              </el-table-column>
              <el-table-column type="selection" width="55"> </el-table-column>
              <!-- id -->
              <el-table-column prop="id" label="id" width="300" v-if="false">
              </el-table-column>
              <!-- 卡号 -->
              <el-table-column prop="num" :label="$t('queryCard.cardNumber')" sortable width="180">
              </el-table-column>
              <!-- 币种 -->
              <el-table-column prop="types" :label="$t('queryCard.currency')" width="90">
              </el-table-column>
              <!-- 创建时间 -->
              <el-table-column prop="createtime" :label="$t('queryCard.creationTime')" width="150">
              </el-table-column>
              <!-- 卡状态 -->
              <el-table-column prop="status" :label="$t('queryCard.cardStatus')" width="130">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 1" type="success">
                    {{ $t('queryCard.normal') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 2" type="danger">
                    {{ $t('queryCard.theCardIsLocked') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 3" type="info">
                    {{ $t('queryCard.cancelledCard') }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- 卡备注 -->
              <el-table-column :label="$t('queryCard.cardNotes')" :sort-method="sortRemarke" :sortable="true">
                <template slot-scope="scope">
                  <el-input :placeholder="$t('queryCard.des2')" @click.native.stop v-model="scope.row.remark"
                    class="input-with-select">
                    <el-button slot="append" icon="el-icon-edit" @click.native.stop="editCardRmarks(scope.row)">
                    </el-button>
                  </el-input>
                </template>
              </el-table-column>
              <!-- 卡余额 -->
              <el-table-column :label="$t('queryCard.cardBalance')" sortable width="150">
                <template slot-scope="scope">
                  {{ scope.row.usdmoney }} USD
                  <br />
                  <el-button v-no-more-click type="primary" icon="el-icon-refresh-left"
                    @click.native.stop="SyncMoney(scope.row)" size="mini">{{ $t('queryCard.synchronous') }}</el-button>
                </template>
              </el-table-column>
              <!-- 操作 -->
              <el-table-column :label="$t('queryCard.operation')">
                <template slot-scope="scope">
                  <el-button size="mini" v-no-click type="success"
                    v-if="scope.row.isrechar != 2 && scope.row.status == 1"
                    @click.native.stop="rechargeCard(scope.row)">{{ $t('queryCard.recharge') }}</el-button>
                  <!-- <el-button
                    size="mini"
                    v-no-more-click
                    type="info"
                    v-if="
                      scope.row.cardshop == 3 &&
                      scope.row.status == 1 &&
                      scope.row.isdisables == 1
                    "
                    @click.native.stop="sk(scope.row)"
                    >锁卡
                  </el-button>
                  <el-button
                    size="mini"
                    v-no-more-click
                    type="warning"
                    v-if="scope.row.cardshop == 3 && scope.row.status == 2"
                    @click.native.stop="jk(scope.row)"
                    >解卡
                  </el-button> -->
                  <el-button size="mini" v-no-more-click type="danger"
                    v-if="scope.row.isopenremove != 2 && scope.row.status != 3" @click.native.stop="xk(scope.row)">{{
                      $t('queryCard.cancellationOfTheCard') }}
                  </el-button>
                  <el-button size="mini" v-no-click type="primary"
                    v-if="scope.row.istoslide != 2 && scope.row.status == 1"
                    @click.native.stop="transferCard(scope.row)">{{ $t('queryCard.rollOut') }}</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 300, 500, 700]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="cardListTotal">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 卡片详情弹出框 -->
    <el-dialog v-dialogDrag :title="$t('queryCard.cardDetails')" custom-class="ls" :visible.sync="dialogVisible"
      width="55%" @close="closeDialog" ref="dialog__wrapper" top="5vh">
      <div class="dialog-body">
        <div class="card-item">
          <div class="card-item__side" @click="copyCardNum()">
            <div class="card-item__focus"></div>
            <div class="card-item__cover"></div>
            <div class="card-item__wrapper">
              <div class="card-item__top">
                <img src="@/images/chip.png" class="card-item__chip" alt="Card chip image" />
                <div class="card-item__type">
                  <img style="position: relative; top: 6px" v-if="card_label == 'MasterCard'"
                    src="@/images/mastercard.png" alt="mastercard brand image" class="card-item__typeImg" />
                  <img style="position: relative; top: 8px" v-else-if="card_label == 'VISA'" src="@/images/visa.png"
                    alt="mastercard brand image" class="card-item__typeImg" />
                  <img style="position: relative; top: 8px" v-else src="@/images/mastercard.png"
                    alt="mastercard brand image" class="card-item__typeImg" />
                </div>
              </div>
              <!-- 显示卡号 -->
              <el-tooltip class="item" effect="dark" :content="$t('queryCard.showCardDetails')" placement="bottom">
                <label class="card-item__number">
                  <span v-for="item in arr.slice(0, 4)">
                    <div class="card-item__numberItem">{{ item }}</div>
                  </span>
                  <span>
                    <div class="-active card-item__numberItem"></div>
                  </span>
                  <span v-for="item in arr.slice(4, 8)">
                    <div class="card-item__numberItem">{{ item }}</div>
                  </span>
                  <span>
                    <div class="-active card-item__numberItem"></div>
                  </span>
                  <span v-for="item in arr.slice(8, 12)">
                    <div class="card-item__numberItem">{{ item }}</div>
                  </span>
                  <span>
                    <div class="-active card-item__numberItem"></div>
                  </span>
                  <span v-for="item in arr.slice(12, 16)">
                    <div class="card-item__numberItem">{{ item }}</div>
                  </span>
                </label>
              </el-tooltip>
              <!-- 显示cvv -->
              <div class="card-item__content">
                <label class="card-item__info">
                  <div class="card-item__holder">CVV</div>
                  <div class="card-item__name">
                    <span class="card-item__nameItem">{{ cvv }}</span>
                  </div>
                </label>
                <!-- 显示有效期 -->
                <div class="card-item__date">
                  <label for="v-card-month" class="card-item__dateTitle" aria-label="Expiration date">Expires</label>
                  <label for="v-card-month" class="card-item__dateItem" aria-label="Card month">
                    <span>{{ expires.substr(2, 2) }}{{ $t('queryCard.month') }}</span> </label>/
                  <label for="v-card-year" class="card-item__dateItem" aria-label="Card year">
                    <span>{{ expires.substr(0, 2) }} {{ $t('queryCard.year') }} </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="el-descriptions">
          <div class="el-dialog__title">
            {{ $t('queryCard.consumptionHistory') }}
            <!-- <el-button type="primary" style="float: right;margin-bottom: 10px;" icon="el-icon-download">下载记录
            </el-button> -->
            <el-button type="primary" v-no-more-click icon="el-icon-refresh" @click="cardsDetails('rf')">{{
              $t('queryCard.flushed') }}</el-button>
            <el-button type="primary" v-no-more-click style="" icon="el-icon-search" @click="getDrawbackList()">{{
              $t('queryCard.refundRecordInquiry') }}
            </el-button>
            <el-button type="primary" v-no-more-click v-if="billAddBtnVisible" icon="el-icon-message"
              @click="openBillAdd()">{{ $t('queryCard.updateTheAddress') }}
            </el-button>
            <!-- <el-tag effect="dark"
              style="position:relative;top:2px;padding: 0 20px;font-size: 20px;height: 40px;line-height: 40px;margin-left: 10px;border-radius: 10px;">
              <span class="el-icon-loading"></span>
              卡片余额:{{ currentMoney }} USD
            </el-tag> -->
          </div>
          <div>
            <el-table :data="consumeData" style="width: 100%" border height="300" max-height="300" size="small"
              highlight-current-row>
              <el-table-column prop="card_num" :label="$t('queryCard.cardNumber')" width="140">
              </el-table-column>
              <el-table-column prop="shopname" :label="$t('queryCard.theNameOfTheBusiness')" width="180">
              </el-table-column>
              <el-table-column prop="createtime" :label="$t('queryCard.orderTime')" width="150"
                :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="money" :label="$t('queryCard.theAmountSpent')" width="100">
              </el-table-column>
              <!-- 交易类型 -->
              <el-table-column :label="$t('queryCard.theTypeOfTransaction')" width="130">
                <template slot-scope="scope">
                  <!-- <el-tag v-if="scope.row.event_type === '6810'" effect="dark" type="success">
                    消费授权(通过)
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === '6890'" effect="dark" type="danger">
                    消费授权(拒绝)
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === '6510'" effect="dark" type="success">
                    退款授权(通过)
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === '6590'" effect="dark" type="danger">
                    退款授权(拒绝)
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === '6930'" effect="dark" type="success">
                    消费授权冲正
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === '6940'" effect="dark" type="danger">
                    退款授权冲正
                  </el-tag> -->
                  <el-tag v-if="scope.row.event_type === 'auth'" effect="dark" type="success">
                    {{ $t('saleType.consume') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'corrective_auth'" effect="dark" type="success">
                    {{ $t('saleType.correctionOfAuthorization') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'verification'" effect="dark" type="success">
                    {{ $t('saleType.verify') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'void'" effect="dark" type="warning">
                    {{ $t('saleType.quash') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'refund'" effect="dark" type="warning">
                    {{ $t('saleType.refund') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'corrective_refund'" effect="dark" type="danger">
                    {{ $t('saleType.validRefunds') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'recharge'" effect="dark" type="success">
                    {{ $t('saleType.recharge') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'recharge_return'" effect="dark" type="success">
                    {{ $t('saleType.cardBalanceRefund') }}
                  </el-tag>
                  <el-tag v-else-if="
                    scope.row.event_type === 'discard_recharge_return'
                  " effect="dark" type="warning">
                    {{ $t('saleType.theCancelledCardIsReturned') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'service_fee'" effect="dark" type="success">
                    {{ $t('saleType.serviceCharge') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'refund_reversal'" effect="dark" type="danger">
                    {{ $t('saleType.revocationOfRefunds') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'Auth'" effect="dark" type="success">
                    {{ $t('saleType.authorizedConsumption') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'Reversal'" effect="dark" type="warning">
                    {{ $t('saleType.refund') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'card_out'" effect="dark" type="warning">
                    {{ $t('saleType.theCardIsTransferredOut') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'card_in'" effect="dark" type="warning">
                    {{ $t('saleType.cardTransferIn') }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- 交易状态 -->
              <el-table-column prop="transactionStatus" :label="$t('queryCard.transactionStatus')" width="130">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.statu === 1" type="success">
                    {{ $t('saleStatus.theTransactionWasSuccessful') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.statu === 2" type="danger">
                    {{ $t('saleStatus.theTransactionFailed') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.statu === 3" type="warning">
                    {{ $t('saleStatus.trading') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.statu === 4" type="success">
                    {{ $t('saleStatus.theTransactionReversalIsSuccessful') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.statu === 5" type="danger">
                    {{ $t('saleStatus.transactionReversalFailed') }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="desct" :label="$t('queryCard.transactionDescription')"
                :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="moneytype" :label="$t('queryCard.theCurrencyOfTheTransaction')" width="70">
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChangeCopy" @current-change="handleCurrentChangeCopy"
              :current-page="queryInfo.currentPageCopy" :page-sizes="[5, 10, 15, 20]"
              :page-size="queryInfo.pageSizeCopy" layout="total, sizes, prev, pager, next, jumper" :total="copyTotal">
            </el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 更新账单地址 -->
    <el-dialog v-dialogDrag :title="$t('queryCard.updateYourBillingAddress')" :visible.sync="billAddVisible" width="40%"
      @close="billDialogClose" ref="dialog__wrapper">
      <el-form label-width="110px" :model="billaddressInfo">
        <el-form-item :label="$t('queryCard.countryCode')">
          <el-input v-model="billaddressInfo.billingCountry"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.provinceState')">
          <el-input v-model="billaddressInfo.billingState"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.city')">
          <el-input v-model="billaddressInfo.billingCity"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.zip')">
          <el-input v-model="billaddressInfo.billingPostalCode"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.fullAddress')">
          <el-input v-model="billaddressInfo.billingAddress"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="billAddVisible = false">{{ $t('queryCard.cancel') }}</el-button>
        <el-button type="primary" @click="changeBillAddress">{{ $t('queryCard.confirm') }}</el-button>
      </span>
    </el-dialog>
    <!-- 充值弹出框 -->
    <el-dialog v-dialogDrag :title="$t('queryCard.cardTopUp')" custom-class="ls" :visible.sync="dialogCz" width="50%"
      @close="czcloseDialog" ref="dialog__wrapper">
      <el-form :label-position="labelPosition" label-width="80px" :model="form_cz">
        <el-form-item :label="$t('queryCard.rechargeCardNumber')">
          <el-input v-model="form_cz.num" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.cardBalance')">
          <el-input v-model="form_cz.usdmoney" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.cardNumberCurrency')">
          <el-input v-model="form_cz.types" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.walletBalanceUsd')">
          <el-input v-model="form_cz.wallet" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.cardRechargeFee')">
          <el-input v-model="form_cz.recharge_char" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.topUpAmount')">
          <el-input v-model="form_cz.cz_balance" @input="getSpend()"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.estimatedCost')">
          <el-input v-model="form_cz.cz_spend" :disabled="true"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('queryCard.rechargeRemarks')">
          <el-input v-model="form_cz.cz_remarks"></el-input>
        </el-form-item> -->
      </el-form>
      <div class="dialog-footer">
        <span slot="footer">
          <el-button @click="dialogCz = false">{{ $t('queryCard.cancel') }}</el-button>
          <el-tooltip v-if="isTs === true" class="item" effect="dark" :content="$t('queryCard.des3')" placement="top">
            <el-button style="font-size: 14px" type="primary" v-no-click @click="cardCz()">{{ $t('queryCard.confirm')
              }}</el-button>
          </el-tooltip>
          <el-button v-if="isTs === false" type="primary" v-no-click @click="cardCz()">{{ $t('queryCard.confirm')
            }}</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 卡号转出弹出框 -->
    <el-dialog v-dialogDrag :title="$t('queryCard.theCardNumberIsTransferredOut')" custom-class="ls"
      :visible.sync="dialogZc" width="50%" @close="closeDialog" ref="dialog__wrapper">
      <el-form :label-position="labelPosition" label-width="100px" :model="form_zc">
        <el-form-item :label="$t('queryCard.transferOutCardNumber')">
          <el-input v-model="form_zc.cardNo" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.cardNumberBalance')">
          <el-input v-model="form_zc.balance" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.theAmountToBeTransferredOut')">
          <el-input v-model="form_zc.zc_balance"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('queryCard.rollOutTheNote')">
          <el-input v-model="form_zc.zc_remarks"></el-input>
        </el-form-item> -->
      </el-form>
      <div class="dialog-footer">
        <span slot="footer">
          <el-button @click="dialogZc = false">{{ $t('queryCard.cancel') }}</el-button>
          <el-button type="primary" v-no-click @click="cardZc()">{{ $t('queryCard.confirm') }}</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 退款记录查询弹窗 -->
    <el-dialog v-dialogDrag :title="$t('queryCard.refundHistory')" custom-class="ls" :visible.sync="tkDialog"
      width="70%" @close="closeDialog" ref="dialog__wrapper">
      <el-table :data="tkData" border stripe style="width: 100%">
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="card_num" :label="$t('queryCard.cardNumber')"> </el-table-column>
        <el-table-column prop="moneytype" :label="$t('queryCard.billingCurrency')" width="180">
        </el-table-column>
        <el-table-column prop="statu" :label="$t('queryCard.transactionStatus')" width="180">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.statu === 1" type="success">
              {{ $t('queryCard.theRefundAuthorizationWasSuccessful') }}
            </el-tag>
            <el-tag v-else-if="scope.row.statu === 2" type="danger">
              {{ $t('queryCard.refundAuthorizationFailed') }}
            </el-tag>
            <el-tag v-else-if="scope.row.statu === 3" type="warning">
              {{ $t('queryCard.refundAuthorization') }}
            </el-tag>
            <el-tag v-else-if="scope.row.statu === 4" type="success">
              {{ $t('queryCard.punchTheRightSuccess') }}
            </el-tag>
            <el-tag v-else-if="scope.row.statu === 5" type="danger">
              {{ $t('queryCard.failedToStraighten') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="money" :label="$t('queryCard.billAmount')"> </el-table-column>
        <el-table-column prop="createtime" :label="$t('queryCard.liquidationTime')"> </el-table-column>
        <el-table-column prop="shopname" :label="$t('queryCard.theNameOfTheBusiness')"> </el-table-column>
        <el-table-column prop="desct" :label="$t('queryCard.remark')"> </el-table-column>
      </el-table>
      <!-- 退款记录表格分页 -->
      <el-pagination align="center" @size-change="tkHandleSizeChange" @current-change="tkHandleCurrentChange"
        :current-page="tkCurrentPage" :page-sizes="[10, 20, 30, 40]" :page-size="tkPagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="tkTotal">
      </el-pagination>
    </el-dialog>

    <!-- 批量充值弹出框 -->
    <el-dialog v-dialogDrag :title="$t('queryCard.bulkRecharge')" custom-class="ls" :visible.sync="dialogPlcz"
      width="50%" @close="dialogPlczClose" ref="dialog__wrapper">
      <el-form :label-position="labelPosition" label-width="80px" :model="form_plcz">
        <el-form-item :label="$t('queryCard.walletBalanceUsd')">
          <el-input v-model="form_plcz.wallet" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.numberOfTopUpCards')">
          <el-input v-model="form_plcz.sums" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.cardRechargeFee')">
          <el-input v-model="form_plcz.recharge_char" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.topUpAmount')">
          <el-input v-model="form_plcz.cz_balance" @input="getPlczje()"></el-input>
        </el-form-item>
        <el-form-item :label="$t('queryCard.estimatedCost')">
          <el-input v-model="form_plcz.cz_spend" :disabled="true"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('queryCard.rechargeRemarks')">
          <el-input v-model="form_plcz.cz_remarks"></el-input>
        </el-form-item> -->
      </el-form>
      <div class="dialog-footer">
        <span slot="footer">
          <el-button @click="dialogPlcz = false">{{ $t('queryCard.cancel') }}</el-button>
          <el-button type="primary" v-no-click @click="plczfs()">{{ $t('queryCard.confirm') }}</el-button>
        </span>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Clipboard from "clipboard";
import BigNumber from "bignumber.js";
import { Loading } from "element-ui";
import { pinyin } from "pinyin-pro";
import { bignumber } from "mathjs";
const lodash = require("lodash");
export default {
  data() {
    return {
      isTs: false,
      arr: [],
      tkId: "",
      cardYe: "",
      tkTotal: 0,
      cardListTotal: 0,
      tkData: [],
      downum: "0",
      card_label: "",
      tkCurrentPage: 1,
      cvv: "",
      expires: "",
      tkPagesize: 10,
      queryInfo: {
        currentPageCopy: 1, // 当前页码
        pageSizeCopy: 5, // 每页数据显示 条数
      },
      usd: "",
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据显示 条数
      selectTimes: "",
      cardsTypesVal: "",
      copyTotal: 0,
      tkDialog: false,
      multipleSelection: [],
      dialogVisible: false,
      dialogZc: false,
      cd_types: "",
      form_plcz: {
        sums: "",
        usdmoney: "",
        wallet: "",
        recharge_char: "0.00",
        cz_balance: "0",
        cz_remarks: "",
        cz_spend: "",
      },
      form_cz: {
        num: "",
        types: "",
        usdmoney: "",
        wallet: "",
        recharge_char: "",
        cz_balance: "0",
        cz_remarks: "",
        cz_spend: "",
      },
      form_zc: {
        id: "",
        balance: "0",
        zc_balance: "",
        cardNo: "",
        zc_remarks: "",
      },
      labelPosition: "right",
      currentMoney: "0.00",
      dialogCz: false,
      dialogPlcz: false,
      cardshop: "",
      // 查询卡片表单集合
      form_queryCards: {
        cardNo: "",
      },
      // 消费记录表格
      consumeData: [],
      // 表格数据
      tableData: [],
      // 表单验证
      form_queryCardsRules: {},
      name: "查询卡片",
      billAddBtnVisible: false, // 账单地址按钮
      billAddVisible: false, // 账单地址弹窗
      billaddressInfo: {}, // 账单地址信息
      cardsTypes: [
        {
          value: "1",
          label: this.$t('queryCard.normal'),
        },
        {
          value: "2",
          label: this.$t('queryCard.theCardIsLocked'),
        },
        {
          value: "3",
          label: this.$t('queryCard.cancelledCard'),
        },
      ],
    };
  },
  methods: {
    // 更新账单地址
    changeBillAddress() {
      const ids = this.tkId;
      let params = {
        id: ids,
        billingCountry: this.billaddressInfo.billingCountry,
        billingCity: this.billaddressInfo.billingCity,
        billingAddress: this.billaddressInfo.billingAddress,
        billingPostalCode: this.billaddressInfo.billingPostalCode,
        billingState: this.billaddressInfo.billingState,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/editCardBillingAddress", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          console.log("res: ", res);
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.getBillAddress();
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 获取账单地址
    getBillAddress() {
      const ids = this.tkId;
      let params = {
        id: ids,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getCardBillingAddress", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          console.log("res: ", res);
          this.billaddressInfo = res.data.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    openBillAdd() {
      this.getBillAddress();
      this.billAddVisible = true;
    },
    billDialogClose() {
      this.billAddVisible = false;
    },
    // 卡备注排序
    sortRemarke(a, b) {
      const a_py = pinyin(a.remark, { toneType: "none" });
      const b_py = pinyin(b.remark, { toneType: "none" });
      // 根据首字母拼音来判断排序
      if (a_py.charCodeAt() > b_py.charCodeAt()) {
        return 1;
      } else if (a_py.charCodeAt() < b_py.charCodeAt()) {
        return -1;
      }
      return 0;
    },
    // 获取手续费
    getsxf(ids) {
      let params = {
        ids: ids,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/batchCardDeRe", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            console.log(res);
            this.form_plcz.recharge_char = res.data.data[0].recharge_char;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 批量充值开始充值
    plczfs() {
      const loading = this.$loading({
        lock: true,
        text: "卡充值请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.multipleSelection[0].cardshop == "2") {
        if (this.form_plcz.cz_balance < 20) {
          this.$message({
            message: "充值金额必须大于20!",
            type: "error",
          });
          loading.close();
          return;
        }
      } else {
        if (this.form_plcz.cz_balance < 5) {
          this.$message({
            message: "充值金额最少为5!",
            type: "error",
          });
          loading.close();
          return;
        }
      }
      let ids = "";
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (i != this.multipleSelection.length - 1) {
          ids = ids + this.multipleSelection[i].id + ",";
        } else if (this.multipleSelection.length == 1) {
          ids = ids + this.multipleSelection[i].id;
        } else {
          ids = ids + this.multipleSelection[i].id;
        }
      }
      let params = {
        ids: ids,
        money: this.form_plcz.cz_balance,
        endmoney: this.form_plcz.cz_spend,
        remark: this.form_plcz.cz_remarks,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/batchCardRecharge", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message({
              message: "充值成功!",
              type: "success",
            });
            if (this.multipleSelection[0].cardshop == "3") {
              setTimeout(this.SyncMoney(this.form_plcz), 6000);
            }
            this.getCardList(), (this.dialogPlcz = false), loading.close();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            }),
              loading.close();
          }
        },
        (error) => {
          loading.close();
          console.log(error);
        }
      );
    },
    // 批量充值计算金额
    getPlczje() {
      // 充值金额 * 卡数量
      let num3 = new BigNumber(this.form_plcz.cz_balance);
      let jgs = "";
      jgs = num3.times(this.multipleSelection.length);
      jgs = jgs.toString();

      // 充值金额 * 充值手续费
      let num4 = new bignumber(this.form_plcz.cz_balance);
      let zjg = "";
      var sxf = this.form_plcz.recharge_char / 100;
      zjg = num4.times(sxf).toString();
      zjg = zjg.substring(0, zjg.indexOf(".") + 4);
      zjg = parseFloat(zjg);
      zjg = zjg.toFixed(2);
      console.log(zjg);

      // 手续费 * 卡数量
      let num6 = new bignumber(zjg);
      let sxfjg = "";
      sxfjg = num6.times(this.multipleSelection.length).toString();

      let num5 = new BigNumber(jgs);
      let je = "";
      je = num5.plus(sxfjg);
      je = je.toString();
      if (je.indexOf(".") > 0) {
        this.form_plcz.cz_spend = je.substring(0, je.indexOf(".") + 3);
      } else {
        this.form_plcz.cz_spend = je;
      }
    },
    // 批量充值关闭
    dialogPlczClose() {
      this.form_plcz.cz_balance = "";
      this.form_plcz.cz_spend = "";
      this.form_plcz.cz_remarks = "";
    },
    // 批量充值
    plcz() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: "请选择充值的卡号后再操作！",
          type: "error",
        });
        return;
      }
      this.dialogPlcz = true;
      this.form_plcz.sums = this.multipleSelection.length;
      let ids = "";
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (i != this.multipleSelection.length - 1) {
          ids = ids + this.multipleSelection[i].id + ",";
        } else if (this.multipleSelection.length == 1) {
          ids = ids + this.multipleSelection[i].id;
        } else {
          ids = ids + this.multipleSelection[i].id;
        }
      }
      this.getsxf(ids);
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 退款记录查询
    getDrawbackList() {
      this.tkDialog = true;
      let params = {
        page: this.tkCurrentPage,
        limit: this.tkPagesize,
        id: this.tkId,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getDrawbackList", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.tkData = res.data.data.data;
          this.tkTotal = res.data.data.total;
          for (let i = 0; i < this.tkData.length; i++) {
            this.tkData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    tkHandleSizeChange(val) {
      this.tkCurrentPage = 1;
      this.tkPagesize = val;
      this.getDrawbackList();
    },
    tkHandleCurrentChange(val) {
      this.tkCurrentPage = val;
      this.getDrawbackList();
    },
    // 卡状态过滤
    filterTagCopy1(value, row) {
      return row.transactionStatus === value;
    },
    // 卡状态过滤
    filterTagCopy(value, row) {
      return row.transactionType === value;
    },
    // 卡状态过滤
    filterTag(value, row) {
      return row.cardsStatus === value;
    }, // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getCardList();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCardList();
    },
    // 修改卡备注
    editCardRmarks(row) {
      let params = {
        id: row.id,
        remark: row.remark,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setCardRemark", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message({
              message: "修改成功!",
              type: "success",
            }),
              this.getCardList();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    sx(ids) {
      let cardid = {
        id: ids,
      };
      cardid = this.$lmjm(cardid);
      const result = this.$http.post("/api/v1/setSyncMoney", cardid, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.currentMoney = res.data.data.money;
          if (res.data.code == "200") {
            this.$message({
              type: "success",
              message: "同步余额成功!",
              duration: 1000,
            });
            this.getCardList();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 表格某一行被选中时
    async cardsDetails(row) {
      this.dialogVisible = true;
      // 请求余额
      // this.sx(row.id)

      if (row.id != undefined && row != "rf") {
        this.tkId = row.id;
      }
      const ids = this.tkId;
      let params = {
        page: this.queryInfo.currentPageCopy,
        limit: this.queryInfo.pageSizeCopy,
        id: ids,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getCarDetails", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          console.log("res: ", res);
          this.consumeData = res.data.data.data;
          this.cvv = res.data.data.card_details.cvv2;
          this.expires = res.data.data.card_details.card_expiration_date;
          if (row.cardshop == "2") {
            this.expires = this.expires.replace("/", "");
            const year = this.expires.substring(2);
            const moth = this.expires.substring(0, 2);
            this.expires = year + moth;
            this.billAddBtnVisible = true;
          }
          this.card_label = res.data.data.card_details.card_label;
          this.copyTotal = res.data.data.total;
          this.splitCardNums(res.data.data.card_details.num);
          for (let i = 0; i < this.consumeData.length; i++) {
            this.consumeData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
          this.getunblockd();
        },
        (error) => {
          console.log(error);
        }
      );
    }, // 页数
    handleSizeChangeCopy(val) {
      this.queryInfo.currentPageCopy = 1;
      this.queryInfo.pageSizeCopy = val;
      this.cardsDetails("fy");
    },
    // 当前页
    handleCurrentChangeCopy(val) {
      this.queryInfo.currentPageCopy = val;
      this.cardsDetails("fy");
    },
    // 拆分卡号
    splitCardNums(val) {
      for (let i = 0; i < val.length; i++) {
        this.arr.push(val.charAt(i));
      }
    },
    // 关闭弹窗
    closeDialog() {
      this.arr = [];
      this.billAddBtnVisible = false;
      this.billaddressInfo = {};
    },
    // 复制卡号
    copyCardNum() {
      let item = "";
      for (let i = 0; i < this.arr.length; i++) {
        item = item + this.arr[i];
      }
      item = item;
      const cInput = document.createElement("input");
      cInput.value = item;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");
      this.$message({
        type: "success",
        message: "复制成功!",
      });
      cInput.remove();
    },
    // 卡充值弹出
    rechargeCard(item) {
      this.isTs = false;
      const loading = this.$loading({
        lock: true,
        text: "充值请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (item.cardshop == "2" && item.card_type == "share") {
        this.isTs = true;
      }
      this.cardshop = item.cardshop;
      let params = {
        id: item.id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getCardDeRe", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.form_cz.id = res.data.data.id;
          this.form_cz.num = res.data.data.num;
          this.form_cz.usdmoney = res.data.data.usdmoney;
          this.form_cz.types = res.data.data.types;
          this.form_cz.wallet = res.data.data.wallet;
          this.form_cz.recharge_char = res.data.data.recharge_char;
          this.cd_types = item.card_type;
          loading.close();
          this.dialogCz = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 下载
    downdocument() {
      let dataList = "";
      // 将导出数据存放在this.mumultipleSelection中 ,遍历将要导出的数据
      this.multipleSelection.forEach((item, index) => {
        dataList = dataList + item.id + ",";
      });
      let params = {
        clientid: this.$cookie.get("client_id"),
        cardids: dataList,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getDownCardIdent", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            const idennum = res.data.data.idennum;
            window.location.href =
              "https://" +
              this.$domain +
              "/api/v1/downdocument?ident=" +
              idennum;
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 卡充值
    cardCz: lodash.throttle(
      function () {
        const loading = this.$loading({
          lock: true,
          text: "卡充值请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        if (this.cardshop == "2") {
          if (this.cd_types == "recharge") {
            if (this.form_cz.cz_balance < 20) {
              this.$message({
                message: "充值金额必须大于20!",
                type: "error",
              });
              loading.close();
              return;
            }
          }
        } else {
          if (this.form_cz.cz_balance < 1) {
            this.$message({
              message: "充值金额最少为1!",
              type: "error",
            });
            loading.close();
            return;
          }
        }
        let params = {
          id: this.form_cz.id,
          money: this.form_cz.cz_balance,
          endmoney: this.form_cz.cz_spend,
          remark: this.form_cz.cz_remarks,
        };
        params = this.$lmjm(params);
        const result = this.$http.post("/api/v1/setCardRecharge", params, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            if (res.data.code == "200") {
              this.$message({
                message: "充值成功!",
                type: "success",
              });
              if (this.cardshop == "3") {
                setTimeout(this.SyncMoney(this.form_cz), 6000);
              }
              this.getCardList(), (this.dialogCz = false), loading.close();
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              }),
                loading.close();
            }
          },
          (error) => {
            loading.close();
            console.log(error);
          }
        );
      },
      1000,
      {
        trailing: false,
      }
    ),
    // 清空
    czcloseDialog() {
      this.form_cz.cz_spend = "";
      this.form_cz.cz_balance = "0";
      this.form_cz.cz_remarks = "";
    },
    // 计算预计花费
    getSpend() {
      if (this.cardshop == "2") {
        this.form_cz.cz_spend =
          this.form_cz.cz_balance * (this.form_cz.recharge_char / 100);
        this.form_cz.cz_spend = this.form_cz.cz_spend / 0.995;
        this.form_cz.cz_spend = this.form_cz.cz_spend.toFixed(2);
        this.form_cz.cz_spend =
          parseFloat(this.form_cz.cz_spend) +
          parseFloat(this.form_cz.cz_balance);

        this.form_cz.cz_spend = Number(this.form_cz.cz_spend).toFixed(4);
        this.form_cz.cz_spend = this.form_cz.cz_spend.substring(
          0,
          this.form_cz.cz_spend.length - 2
        );
      } else {
        this.form_cz.cz_spend =
          parseFloat(this.form_cz.cz_balance) *
          parseFloat(this.form_cz.recharge_char / 100);
        this.form_cz.cz_spend =
          parseFloat(this.form_cz.cz_spend) +
          parseFloat(this.form_cz.cz_balance);

        this.form_cz.cz_spend = Number(this.form_cz.cz_spend).toFixed(4);
        this.form_cz.cz_spend = this.form_cz.cz_spend.substring(
          0,
          this.form_cz.cz_spend.length - 2
        );
      }
    },
    // 卡转出弹窗
    transferCard(item) {
      this.cardshop = item.cardshop;
      this.dialogZc = true;
      this.form_zc.cardNo = item.num;
      let params = {
        id: item.id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getNewCardUsd", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.form_zc.balance = res.data.data.usdmoney;
          this.form_zc.id = item.id;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 卡转出
    cardZc: lodash.throttle(
      function () {
        const loading = this.$loading({
          lock: true,
          text: "卡转出请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        if (this.cardshop == "2") {
          if (this.form_zc.balance - this.form_zc.zc_balance < 1) {
            this.$message({
              message:
                "你需要在卡上至少保留1美元。如果您不再使用该卡，您可以销卡，我们将把卡上的所有资金退还给您。",
              type: "error",
            });
            loading.close();
            return;
          }
        }
        let params = {
          id: this.form_zc.id,
          money: this.form_zc.zc_balance,
          remark: this.form_zc.zc_remarks,
        };
        params = this.$lmjm(params);
        const result = this.$http.post("/api/v1/setCardRollOut", params, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            if (res.data.code == "200") {
              this.$message({
                message: "转出成功!",
                type: "success",
              });
              if (this.cardshop == "3") {
                setTimeout(this.SyncMoney(this.form_zc), 6000);
              }
              this.getCardList();
              this.dialogZc = false;
              loading.close();
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              }),
                loading.close();
            }
          },
          (error) => {
            console.log(error);
            loading.close();
          }
        );
      },
      1000,
      {
        trailing: false,
      }
    ),
    // 锁卡
    sk(item) {
      this.$confirm("此操作将锁定卡号" + item.num + ", 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: item.id,
          };
          params = this.$lmjm(params);
          const result = this.$http.post("/api/v1/setLockCard", params, {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          });
          result.then(
            (res) => {
              if (res.data.code == "200") {
                this.$message({
                  type: "success",
                  message: "锁卡成功!",
                }),
                  this.getCardList();
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消冻结",
          });
        });
    },
    jk(row) {
      this.$confirm("此操作将解锁卡号" + row.num + ", 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: row.id,
          };
          params = this.$lmjm(params);
          const result = this.$http.post("/api/v1/setunLockCard", params, {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          });
          result.then(
            (res) => {
              if (res.data.code == "200") {
                this.$message({
                  type: "success",
                  message: "解卡成功!",
                }),
                  this.getCardList();
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消冻结",
          });
        });
    },
    // 获取用户余额
    getUserInfo() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getuserinfos", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.usd = res.data.data.usd;
          this.form_plcz.wallet = res.data.data.usd;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    xk(item) {
      this.$confirm(
        "此操作将注销卡号" + item.num + ",卡注销后将无法再次使用, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (item.cardshop == "3") {
            if (item.usdmoney > 0 && item.card_type == "normal") {
              this.$message({
                type: "error",
                message: "请清空卡内余额再进行销卡！",
              });
              return;
            }
          }
          // else if(item.cardshop == "2"){
          //   if(item.usdmoney > 1){
          //     this.$message({
          //       type: 'error',
          //       message: "请先转出多余资金保留1美金后再进行销卡！"
          //     })
          //     return
          //   }
          // }
          let params = {
            id: item.id,
          };
          params = this.$lmjm(params);
          const result = this.$http.post("/api/v1/setCanCard", params, {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          });
          result.then(
            (res) => {
              if (res.data.code == "200") {
                this.$message({
                  type: "success",
                  message: "销卡成功!",
                }),
                  this.getCardList();
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消注销",
          });
        });
    },
    // 获取卡号
    getCardList() {
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        card_num: this.form_queryCards.cardNo,
        statu: this.cardsTypesVal,
        createtime: this.selectTimes,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getCardList", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.downum = res.data.data.downum;
          this.tableData = res.data.data.data;
          this.cardListTotal = res.data.data.total;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
          this.getUserInfo();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 刷新
    ref: lodash.throttle(
      function () {
        this.form_queryCards.cardNo = "";
        this.cardsTypesVal = "";
        this.selectTimes = "";
        let params = {
          page: "1",
          limit: "100",
          card_num: "",
          statu: "",
          createtime: "",
        };
        params = this.$lmjm(params);
        const result = this.$http.post("/api/v1/getCardList", params, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            this.tableData = res.data.data.data;
            this.cardListTotal = res.data.data.total;
            for (let i = 0; i < this.tableData.length; i++) {
              this.tableData[i].createtime = this.getTime(
                res.data.data.data[i].createtime
              );
            }
            this.getUserInfo();
          },
          (error) => {
            console.log(error);
          }
        );
      },
      5000,
      {
        trailing: false,
      }
    ),
    // 同步余额
    SyncMoney(row) {
      let params = {
        id: row.id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setSyncMoney", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if ((res.data.code = "200")) {
            this.$message({
              message: "同步刷新余额成功!",
              type: "success",
            });
            this.getCardList();
          } else {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 获取卡详情
    getunblockd() {
      let params = {
        id: this.tkId,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getunblockd", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          console.log(res);
          this.cvv = res.data.data.card_details.cvv2;
          this.expires = res.data.data.card_details.card_expiration_date;
          if (this.expires.indexOf("-") > 0) {
            this.expires =
              this.expires.substring(2, 4) + this.expires.substring(5, 7);
          } else if (this.expires.indexOf("/") > 0) {
            this.expires =
              this.expires.substring(3, 5) + this.expires.substring(0, 2);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  created() {
    this.getCardList();
    this.getUserInfo();
  },

  mounted() { },
};
</script>

<style scoped>
.dialog-footer {
  width: 50%;
  position: relative;
  left: 75%;
}

.dialog-footer button {
  position: relative;
}

.el-select,
.el-input--prefix {
  margin-right: 1%;
}

.el-input--prefix {
  width: 15%;
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;
}

.card-item__side {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 20px 60px #0e2a5a8c;
  transform: perspective(2000px) rotateY(0) rotateX(0) rotate(0);
  transform-style: preserve-3d;
  transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
}

.card-item__focus {
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.65);
}

.card-item__cover {
  position: absolute;
  height: 100%;
  background-color: #1c1d27;
  background-image: linear-gradient(170deg, #45d3d3 0, #4d9be8 74%);
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}

.card-item__wrapper {
  font-family: Source Code Pro, monospace;
  padding: 25px 15px;
  position: relative;
  z-index: 4;
  height: 100%;
  text-shadow: 7px 6px 10px rgb(14 42 90 / 80%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.card-item__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 10px;
}

.card-item__number {
  font-weight: 500;
  line-height: 1;
  color: #fff;
  font-size: 27px;
  margin-bottom: 20px;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}

.card-item__content {
  color: #fff;
  display: flex;
  align-items: flex-start;
}

.card-item__info {
  color: #fff;
  width: 100%;
  max-width: calc(100% - 85px);
  padding: 10px 15px;
  font-weight: 500;
  display: block;
  cursor: pointer;
  margin-left: 20px;
}

.card-item__date {
  flex-wrap: wrap;
  font-size: 18px;
  margin-left: auto;
  padding: 10px;
  display: inline-flex;
  width: 120px;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
}

.card-item__dateTitle {
  opacity: 0.7;
  font-size: 20px;
  padding-bottom: 6px;
  width: 100%;
  margin-left: 10px;
}

.card-item__dateItem {
  position: relative;
  font-size: 20px;
}

.card-item__dateItem span {
  width: 45px;
  display: inline-block;
}

.card-item__holder {
  opacity: 0.7;
  font-size: 20px;
  margin-bottom: 6px;
}

.card-item__name {
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
}

.card-item__nameItem {
  display: inline-block;
  min-width: 8px;
  position: relative;
  font-size: 22px;
}

.card-item__numberItem {
  width: 16px;
  display: inline-block;
}

.card-item__typeImg {
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  max-height: 100%;
  -o-object-position: top right;
  object-position: top right;
}

.card-item__type {
  height: 45px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 100px;
  margin-left: auto;
  width: 100%;
}

.card-item__chip {
  width: 60px !important;
}

.card-item__numberItem.-active {
  width: 25px;
}

.el-dialog__title {
  margin-bottom: 20px;
  margin-top: 50px;
}

.box {
  width: 400px;

  .top {
    text-align: center;
  }

  .left {
    float: left;
    width: 60px;
  }

  .right {
    float: right;
    width: 60px;
  }

  .bottom {
    clear: both;
    text-align: center;
  }

  .item {
    margin: 4px;
  }

  .left .el-tooltip__popper,
  .right .el-tooltip__popper {
    padding: 8px 10px;
  }
}

.el-tooltip {
  font-size: 35px;
  margin-left: 16px;
}

.card-item__content {
  font-size: 30px;
}
</style>
<style>
.el-tooltip__popper {
  max-width: 10%;
}
</style>
