<template>
  <el-main>
    <div class="title_tab">
      {{ name }}
      <div style="float: right; line-height: 35px">
      </div>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <!-- 搜索框 -->
          <div class="user_box">
            <el-form :model="form_balance" :rules="form_balanceRules" ref="form_balanceFormRef"
              :label-position="labelPosition">
              <!-- 输入卡号 -->
              <el-input v-model="form_balance.keywords" prefix-icon="el-icon-tickets" placeholder="请输入关键字查询">
              </el-input>
              <!-- 日期选择 -->
              <!-- <el-date-picker v-model="dataTimes" type="daterange" align="right" unlink-panels range-separator="-"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions2"
                format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
              </el-date-picker> -->
              <!-- 事件类型 -->
              <!-- <el-select v-model="selectVlues" placeholder="请选择事件类型" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select> -->
              <!-- 支付状态 -->
              <!-- <el-select v-model="selelctRechargeStatusVal" placeholder="请选择支付状态" clearable>
                <el-option v-for="item in selelctRechargeStatus" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select> -->
              <el-button type="primary" icon="el-icon-search" @click="searchUser()">搜 索</el-button>
              <el-button type="primary" icon="el-icon-refresh" @click="searchUser('1')">刷 新</el-button>
              <el-button type="primary" icon="el-icon-plus" @click="openConfig">新增</el-button>
            </el-form>
          </div>
          <!-- 表格 + 分页 -->
          <div class="user_box">
            <el-table :data="tableData" style="width: 100%" border height="640" max-height="800" size="small"
              highlight-current-row>
              <!-- <el-table-column label="编号" prop="mycard_id" :show-overflow-tooltip="true">
              </el-table-column> -->
              <el-table-column label="类型" prop="type"> </el-table-column>
              <el-table-column label="关键词" prop="keywords">
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="200">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="editorConfig(scope.row)">编辑</el-button>
                  <el-button size="mini" type="danger" @click="removeConfig(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[50, 100, 150, 200]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
          <!-- 新增配置 -->
          <el-dialog custom-class="ls" title="新增配置" :visible.sync="dialogXz" width="30%" ref="dialog__wrapper">
            <el-form :label-position="labelPosition" label-width="80px" :model="form_xz"
              @close="dialogXz = false, this.form_xz = {}">
              <el-form-item label="类型">
                <el-input v-model="form_xz.type"></el-input>
              </el-form-item>
              <el-form-item label="关键词">
                <el-input v-model="form_xz.keywords"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogXz = false">取 消</el-button>
              <el-button type="primary" @click="addConfig">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      gshareusdmoney: "",
      pshareusdmoney: "",
      cny: "",
      usd: "",
      name: "配置名单",
      dialogXz: false, // 新增配置
      form_xz: {},
      total: 0,
      labelPosition: "right",
      selectVlues: "",
      selelctRechargeStatusVal: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 50, // 每页数据显示 条数
      dataTimes: [],
      form_balance: {
        cardNo: "",
      },
      form_balanceRules: {},
      pickerOptions2: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    editorConfig(item) {
      console.log('item: ', item);
      this.form_xz = item
      this.dialogXz = true
    },
    removeConfig(item) {
      this.$confirm('是否删除该配置?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: item.id
        };
        params = this.$lmjm(params);
        const result = this.$http.post("/api/v1/delBlacklistKeywords", params, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            this.$message({
              type: 'success',
              message: '已成功删除'
            });
            this.searchUser()
          },
          (error) => {
            console.log(error);
          }
        );
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    openConfig() {
      this.form_xz = {}
      this.dialogXz = true
    },
    addConfig() {
      let params = this.form_xz;
      params = this.$lmjm(params);
      if (this.form_xz.id) {
        const result = this.$http.post("/api/v1/editBlacklistKeywords", params, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            this.form_xz = {}
            this.dialogXz = false
            this.searchUser()
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        const result = this.$http.post("/api/v1/addBlacklistKeywords", params, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            this.form_xz = {}
            this.dialogXz = false
            this.searchUser()
          },
          (error) => {
            console.log(error);
          }
        );
      }

    },
    getCommonNum() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getCommonNum", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.cny = res.data.data.cnycount;
          this.usd = res.data.data.usdcount;
          this.pshareusdmoney = res.data.data.pshareusdmoney;
          this.gshareusdmoney = res.data.data.gshareusdmoney;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 搜索
    searchUser(rf) {
      if (rf == "1") {
        this.currentPage = 1;
        this.pageSize = 50;
        this.form_balance.keywords = "";
        this.selectVlues = "";
        this.selelctRechargeStatusVal = "";
        this.dataTimes = [];
      }
      if (this.dataTimes[0] == undefined && this.dataTimes[1] == undefined) {
        this.dataTimes[0] = "";
        this.dataTimes[1] = "";
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        keywords: this.form_balance.keywords,
        event_type: this.selectVlues,
        pay_type: this.selelctRechargeStatusVal,
        start: this.dataTimes[0],
        ends: this.dataTimes[1],
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getBlacklistKeywords", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          // for (let i = 0; i < this.tableData.length; i++) {
          //   this.tableData[i].addtime = this.getTime(
          //     res.data.data.data[i].addtime
          //   );
          // }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.searchUser();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchUser();
    },
  },
  created() {
    this.searchUser();
    this.getCommonNum();
  },
  mounted() { },
};
</script>

<style scoped>
.el-select,
.el-input--prefix {
  margin-right: 1%;
}

.el-input--prefix {
  width: 15%;
}

.el-range-editor {
  margin-right: 3%;
}

.el-button {
  margin-right: 2%;
}
</style>
